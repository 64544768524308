import React, { HtmlHTMLAttributes, ReactNode, ForwardedRef } from 'react';
import styles from './overlay.module.css';

interface Props extends HtmlHTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const Overlay = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...rest }, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div className={styles.overlay} {...rest} ref={ref}>
        {children}
      </div>
    );
  },
);
