/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { XSquare } from 'phosphor-react';
import { useCallback, useEffect, useState } from 'react';
import styles from './notification-component.module.css';
import { usePlayAudio } from '../../hooks/usePlayAudio';
import notificationRingTone from '../../assets/audio/notification.mp3';
import pattern from '../../assets/img/modal-pattern.svg';

interface Props {
  title?: string;
  content?: string;
  onClose?: () => void;
  onClick: () => void;
}

export const NotificationComponent = ({
  title,
  content,
  onClick,
  onClose,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [alreadyPlayed, setAlreadyPlayed] = useState(false);
  const { player } = usePlayAudio({ audioPath: notificationRingTone });

  const handleClose = useCallback(() => {
    setIsOpen(false);

    if (onClose) onClose();
  }, [onClose]);

  useEffect(() => {
    if (!alreadyPlayed) {
      player.onPlay();
      setAlreadyPlayed(true);
    }
  }, [player, alreadyPlayed]);

  if (isOpen)
    return (
      <div className={`${styles.container} ${styles.animate}`}>
        <div className={styles.header}>
          <img src={pattern} alt="fundo" className={styles.pattern} />
          {title && (
            <h1 className={styles.title} onClick={onClick}>
              {title}
            </h1>
          )}
          <button type="button" onClick={handleClose}>
            <XSquare size={32} />
          </button>
        </div>
        <div className={styles.body} onClick={onClick}>
          {content && <p className={styles.content}>{content}</p>}
        </div>
      </div>
    );
  return null;
};
