import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Input, Option } from '../../../../../../../../../../shared/Input';
import { FormValues } from '../../useQuotationForm';
import { maskLicensePlate } from '../../../../../../../../../../services/helpers/mask';
import { fetchVehicleDataByLicensePlateController } from '../../../../../../../../../../services/dataFetchers/VehicleDataFetcher/VehicleDataFetcher';
import styles from './vehicle-info.module.css';
import { VehicleResponse } from '../../../../../../../../../../services/dataFetchers/VehicleDataFetcher/model';
import { PaymentTypes } from '../../../../../../interface';

interface Props {
  control: Control<FormValues, any>;
  handleVehicleFields: (vehicle: VehicleResponse) => void;
  paymentTypes: PaymentTypes[];
}

export const VehicleInfo = ({
  control,
  handleVehicleFields,
  paymentTypes,
}: Props) => {
  const [isLoadingVehicle, setIsLoadingVehicle] = useState(false);
  const [isFoundVehicle, setIsFound] = useState(false);

  const handleVehicleSearch = async (plate: string) => {
    setIsLoadingVehicle(true);
    const res = await fetchVehicleDataByLicensePlateController(plate);
    if (res) {
      handleVehicleFields(res);
    }
    setIsLoadingVehicle(false);
    setIsFound(true);
  };

  const paymentTypeOptions: Option[] = [
    {
      name: 'Escolha uma opção',
      value: 'default',
      color: '#89939E',
    },
    ...paymentTypes.map(paymentType => ({
      name: paymentType,
      value: paymentType,
      color: 'black',
    })),
  ];
  return (
    <div className={styles['vehicle-info']}>
      <div className={styles.row}>
        <Controller
          name="plate"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Placa:"
              value={maskLicensePlate(value || '')}
              isLoading={isLoadingVehicle}
              maxLength={7}
              handleChange={e => {
                const formattedPlate = maskLicensePlate(e.target.value);
                if (formattedPlate.length === 7) {
                  handleVehicleSearch(formattedPlate);
                } else {
                  setIsFound(false);
                }
                onChange(e);
              }}
              style={{
                background: isFoundVehicle ? 'var(--tint_5)' : '',
              }}
            />
          )}
        />
        <Controller
          name="year"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Ano:"
              value={value}
              handleChange={onChange}
              disabled
              style={{
                background: isFoundVehicle ? 'var(--tint_5)' : '',
                border: isFoundVehicle ? 'none' : '',
              }}
            />
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          name="vehicle"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Veículo:"
              value={value}
              handleChange={onChange}
              disabled
              style={{
                background: isFoundVehicle ? 'var(--tint_5)' : '',
                border: isFoundVehicle ? 'none' : '',
              }}
            />
          )}
        />
      </div>
      <div className={styles.row}>
        <Controller
          name="chassi"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Chassi:"
              value={value}
              handleChange={onChange}
              disabled
              style={{
                background: isFoundVehicle ? 'var(--tint_5)' : '',
                border: isFoundVehicle ? 'none' : '',
              }}
            />
          )}
        />
        <Controller
          name="paymentMethod"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <Input
              name={name}
              label="Forma de Pagamento:"
              value={value}
              handleChange={onChange}
              type="select"
              options={paymentTypeOptions}
              style={{
                fontWeight: '500',
                fontSize: '1.125rem',
              }}
              className={styles['payment-select']}
            />
          )}
        />
      </div>
    </div>
  );
};
