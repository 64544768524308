import { CSSProperties, ReactNode } from 'react';
import { ListLoader } from '../../../ListLoader';
import styles from './root.module.css';

interface Props {
  isLoading?: boolean;
  notFound?: boolean;
  isEmpty?: boolean;
  message?: string;
  children: ReactNode;
  style?: CSSProperties;
}

export const Root = ({
  isEmpty = false,
  isLoading = false,
  notFound = false,
  message = '',
  children,
  style,
}: Props) => {
  return (
    <div className={styles.container} style={style}>
      {children}
      {!isLoading && (isEmpty || notFound) && (
        <p className={styles.message}>{message}</p>
      )}
      {isLoading && <ListLoader />}
    </div>
  );
};
