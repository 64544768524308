import { useCallback, useEffect, useState } from 'react';
import {
  getAnswerQuotationByIdController,
  getMyAnswersController,
} from '../../../controller';
import { AnswerQuotation } from '../../../../../../../@types/interface';
import { useAnswerQuotation } from '../../../../../../../contexts/answerQuotationContext';
import useSelectedAnswer from '../../../hooks/useSelectedAnswer';

const LIMIT = 80;

export const useApprovedQuotations = () => {
  const { setAnswerQuotationState, updateAnswerQuotations } =
    useAnswerQuotation();

  const selectedAnswer = useSelectedAnswer();

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);

  const fetchApprovedAnsweredQuotations = useCallback(
    async (pageRequest: number) => {
      setIsLoading(true);
      if (!noMoreData) {
        const res = await getMyAnswersController({
          limit: LIMIT,
          page: pageRequest,
          option: 'not_confirmed',
        });

        if (res && res.length > 0) {
          setAnswerQuotationState(previous => {
            const newAnswers = res.filter(
              answer =>
                !previous.pendingQuotationList.some(
                  existingQuotation =>
                    answer.id_answer_quotation ===
                    existingQuotation.id_answer_quotation,
                ),
            );
            return {
              ...previous,
              pendingQuotationList: [
                ...previous.pendingQuotationList,
                ...newAnswers,
              ],
            };
          });
        } else {
          setNoMoreData(true);
        }
      }
      setIsLoading(false);
    },
    [noMoreData, setAnswerQuotationState],
  );

  const loadMoreData = useCallback(
    (previousPage = 1) => {
      if (!noMoreData) {
        fetchApprovedAnsweredQuotations(previousPage);
        setPage(previousPage + 1);
      }
    },
    [fetchApprovedAnsweredQuotations, noMoreData],
  );

  useEffect(() => {
    loadMoreData();
  }, [loadMoreData]);

  const handleRowClick = (answer: AnswerQuotation) => {
    if (
      !selectedAnswer.answer ||
      selectedAnswer.answer.quotation_id !== answer.quotation_id
    ) {
      selectedAnswer.onSelect(answer);
    } else {
      selectedAnswer.onClear();
    }
  };

  const fetchQuotationAnswerById = useCallback(
    async (answerId: string) => {
      const res = await getAnswerQuotationByIdController(answerId);
      if (res) {
        selectedAnswer.onSelect(res);
        updateAnswerQuotations(res);
      }
    },
    [selectedAnswer, updateAnswerQuotations],
  );

  const resetStates = useCallback(async () => {
    // reset search controllers
    setNoMoreData(false);
    setPage(0);
  }, []);

  return {
    page,
    loadMoreData,
    fetchApprovedAnsweredQuotations,
    isLoading,
    handleRowClick,
    fetchQuotationAnswerById,
    resetStates,
  };
};
