import { CopySimple, ArchiveBox, CheckSquare } from '@phosphor-icons/react';
import { Circle } from '../../../../../../shared/Circle';
import { PageLayout } from '../../../../../../shared/PageLayout';
import styles from './mobile-header-quotation.module.css';
import { useAnswerQuotation } from '../../../../../../contexts/answerQuotationContext';

type Props = {
  notAnsweredCounter: number;
};

export const MobileHeaderAnswerQuotation = ({ notAnsweredCounter }: Props) => {
  const { selectedPage, setSelectedPage } = useAnswerQuotation();

  const handleNameButton = () => {
    if (selectedPage === 'notAnswered') {
      return ['Recebidas', <ArchiveBox size={32} weight="fill" />];
    }

    if (selectedPage === 'approved') {
      return ['Confirmar Pedido', <CheckSquare size={32} weight="fill" />];
    }
    if (selectedPage === 'finished')
      return ['Finalizadas', <CopySimple size={32} weight="fill" />];

    return ['Recebidas', <ArchiveBox size={32} weight="fill" />];
  };

  const handleHideButton = () => {
    if (selectedPage === 'notAnswered') {
      return 'notAnswered';
    }
    if (selectedPage === 'approved') {
      return 'approved';
    }
    return 'finished';
  };

  return (
    <div className={styles.contentButton}>
      <PageLayout.TopMenuButton onClick={() => console.log('')} isSelected>
        {handleNameButton()[1]}
        {handleNameButton()[0]}
        {selectedPage === 'notAnswered' &&
          notAnsweredCounter > 0 &&
          ` (${notAnsweredCounter})`}{' '}
        {selectedPage === 'notAnswered' && notAnsweredCounter > 0 && (
          <Circle animation="pulse" variant="notification" />
        )}
      </PageLayout.TopMenuButton>

      <div
        className={`${styles.containerDropDownOpen} ${
          styles[handleHideButton()]
        }`}
      >
        <PageLayout.TopMenuButton
          isSelected={selectedPage === 'notAnswered'}
          onClick={() => setSelectedPage('notAnswered')}
        >
          <ArchiveBox size={32} weight="fill" />
          Recebidas
          {notAnsweredCounter > 0 && ` (${notAnsweredCounter})`}{' '}
          {notAnsweredCounter > 0 && (
            <Circle animation="pulse" variant="notification" />
          )}
        </PageLayout.TopMenuButton>
        <PageLayout.TopMenuButton
          isSelected={selectedPage === 'approved'}
          onClick={() => {
            setSelectedPage('approved');
          }}
        >
          <CheckSquare size={32} weight="fill" />
          Confirmar Pedido
        </PageLayout.TopMenuButton>
        {selectedPage !== 'finished' && (
          <PageLayout.TopMenuButton
            onClick={() => {
              setSelectedPage('finished');
            }}
          >
            <CopySimple size={32} weight="fill" />
            Finalizadas
          </PageLayout.TopMenuButton>
        )}
      </div>
    </div>
  );
};
