import api from '../../services/api/api';

interface GetVersionResponse {
  id_version: string;
  type: string;
  version: string;
  value: null | string;
  updated_at: Date;
}

export const getCurrentVersion = async () => {
  const { data } = await api.get<GetVersionResponse>('/version/web');
  return data;
};
