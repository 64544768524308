import { User } from '../@types/interface';

export const getWorkshopData = () => {
  const localUser: User = JSON.parse(
    localStorage.getItem('@AutoCenter: user') || '{}',
  );
  const { workshop } = localUser;
  return workshop;
};
export const getUserData = () => {
  const localUser: User = JSON.parse(
    localStorage.getItem('@AutoCenter: user') || '{}',
  );
  return localUser;
};

export const getAccessToken = () => {
  const token: string = localStorage.getItem('@AutoCenter: accessToken') || '';

  return token;
};
