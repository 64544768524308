import { getUserData } from '../../utils/workshopData';

export const passwordHasChanged = () => {
  const user = getUserData();

  if (user) {
    return user?.is_first_access_after_recovery || user?.is_first_access;
  }

  return false;
};
