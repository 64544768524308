import * as yup from 'yup';
import { handleError } from '../../../../utils/handleError';
import {
  ApproveQuotationInput,
  ChangeQuotationStateInput,
  CreateQuotationInput,
  GetMyQuotationsProps,
  IncreaseQuotationHoursInput,
  SendQuotationInput,
} from './interface';
import {
  approveQuotation,
  changeQuotationState,
  createQuotation,
  createQuotationDraft,
  deleteQuotation,
  findFavoritesWorkshops,
  findFavoritesWorkshopsV2,
  getAllMyQuotations,
  getMyQuotations,
  getQuotationById,
  getQuotationPaymentTypes,
  increaseQuotationHours,
  resendQuotation,
  sendQuotation,
  transformDraftInQuotation,
  updateQuotationDraft,
} from './model';
import { handleErrorMessage } from '../../../../utils/handleErrorMessage';
import { QuotationDraftInput } from '../../../../@types/interface';

export const getMyQuotationsController = async (
  props: GetMyQuotationsProps,
) => {
  try {
    const res = await getMyQuotations({ ...props });

    return res;
  } catch (err) {
    handleError(err);
  }
};
export const getAllMyQuotationsController = async (
  props: Omit<GetMyQuotationsProps, 'option'>,
) => {
  try {
    const res = await getAllMyQuotations({ ...props });

    return res;
  } catch (err) {
    handleError(err);
  }
};
export const getQuotationByIdController = async (id: string) => {
  try {
    const res = await getQuotationById(id);

    return res;
  } catch (err) {
    handleError(err);
  }
};

export const createQuotationController = async (
  props: CreateQuotationInput,
) => {
  try {
    const schema = yup.object().shape({
      vehicleId: yup.string(),
      items: yup.array().of(
        yup.object().shape({
          description: yup.string(),
          reference: yup.string(),
          quantity: yup.string(),
        }),
      ),
      comment: yup.string(),
      imagesList: yup.array().of(yup.mixed()),
      hours: yup.string(),
      type_payment: yup.string(),
    });

    await schema.validate(
      { ...props },
      {
        abortEarly: false,
      },
    );
    const res = await createQuotation({ ...props });

    return res;
  } catch (err) {
    handleError(err);
    return false;
  }
};
export const sendQuotationController = async (props: SendQuotationInput) => {
  try {
    const schema = yup.object().shape({
      quotationId: yup.string().required(),
      sendTo: yup.array().of(yup.string()),
    });

    await schema.validate(
      { ...props },
      {
        abortEarly: false,
      },
    );
    const res = await sendQuotation({ ...props });

    return res;
  } catch (err) {
    handleError(err);
    return false;
  }
};
export const resendQuotationController = async (props: SendQuotationInput) => {
  try {
    const schema = yup.object().shape({
      quotationId: yup.string().required(),
      sendTo: yup.array().of(yup.string()),
    });

    await schema.validate(
      { ...props },
      {
        abortEarly: false,
      },
    );
    const res = await resendQuotation({ ...props });

    return res;
  } catch (err) {
    handleError(err);
    return false;
  }
};

export const approveQuotationController = async (
  props: ApproveQuotationInput,
) => {
  try {
    const schema = yup.object().shape({
      quotation_id: yup.string(),
      list_answers_approved: yup.array().of(
        yup.object().shape({
          answer_quotation_id: yup.string(),
          rebuttal: yup.string(),
          items: yup.array().of(
            yup.object().shape({
              item_answer_quotation_id: yup.string(),
              quantity_asked: yup.string(),
            }),
          ),
        }),
      ),
    });

    await schema.validate(props, { abortEarly: false });

    const res = await approveQuotation(props);
    return { status: 'success', data: res };
  } catch (err) {
    handleError(err);
    const message = handleErrorMessage(err);
    return { status: 'error', data: message };
  }
};

export const changeQuotationStateController = async (
  props: ChangeQuotationStateInput,
) => {
  try {
    const res = await changeQuotationState({ ...props });
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const increaseQuotationHoursController = async (
  props: IncreaseQuotationHoursInput,
) => {
  try {
    const schema = yup.object().shape({
      quotation_id: yup.string(),
      increase: yup.number(),
    });

    await schema.validate(props, { abortEarly: false });

    const res = await increaseQuotationHours({ ...props });
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const getQuotationPaymentTypesController = async () => {
  try {
    const res = await getQuotationPaymentTypes();
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const deleteQuotationController = async (id: string) => {
  try {
    const res = await deleteQuotation(id);
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const findFavoritesWorkshopsController = async (
  name = '',
  type = '',
) => {
  try {
    const res = await findFavoritesWorkshops(name, type);

    return res;
  } catch (err) {
    handleError(err);
  }
};

export const findFavoritesWorkshopsV2Controller = async (name = '') => {
  try {
    const res = await findFavoritesWorkshopsV2(name);

    return res;
  } catch (err) {
    handleError(err);
  }
};

export const createQuotationDraftController = async () => {
  try {
    const res = await createQuotationDraft();

    return res;
  } catch (err) {
    handleError(err);
  }
};

export const updateQuotationDraftController = async (
  data: QuotationDraftInput,
) => {
  try {
    const res = await updateQuotationDraft({ ...data });

    return res;
  } catch (err) {
    handleError(err);
  }
};

export const transformDraftInQuotationController = async (id: string) => {
  try {
    const res = await transformDraftInQuotation(id);

    return res;
  } catch (err) {
    handleError(err);
  }
};
