import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { WarningCircle } from 'phosphor-react';
import styles from './create-agent-modal.module.css';
import {
  addAgentPermissionsController,
  createAgentController,
  getAgentPermissionsController,
} from '../../controller';
import { maskCpfCnpj, maskPhone } from '../../../../../services/helpers/mask';
import { Checkbox } from '../../../../../shared/Checkbox';
import { AgentPermissionsResponse, AgentResponse } from '../../interface';
import { InputComponent } from '../../../Finance/pages/Transactions/components/input';
import { createAgentDTO, ICreateAgentDTO } from './createAgentDTO';
import { ButtonComponent } from '../../../Finance/pages/Transactions/components/Button';
import { ModalContent, ModalTitle } from '../../../Finance/components/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (res: AgentResponse) => void;
  idWorshop: string;
}

interface FormValues {
  company_id: string;
  name: string;
  document: string;
  phone?: string;
  class_agent_id?: string;
  max_points?: string;
}

export const CreateAgentModal = ({
  isOpen,
  onClose,
  idWorshop,
  onCreate,
}: Props) => {
  const {
    control,
    getValues,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ICreateAgentDTO>({
    defaultValues: {
      company_id: '',
      name: '',
      document: '',
      phone: '',
    },
    resolver: yupResolver(createAgentDTO),
  });
  const [agentPermissions, setAgentPermissions] = useState<
    AgentPermissionsResponse[]
  >([]);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  useEffect(() => {
    reset({ ...getValues(), company_id: idWorshop } as FormValues);
    return () => {
      reset({} as FormValues);
      setSelectedPermissions([]);
    };
  }, [isOpen, reset, getValues, idWorshop]);

  useEffect(() => {
    fetchAgentPermissions();
  }, []);

  const fetchAgentPermissions = async () => {
    const res = await getAgentPermissionsController();
    if (res) {
      setAgentPermissions(res);
    }
  };
  const addAgentPermissions = async (user_id: string) => {
    const res = await addAgentPermissionsController({
      user_id,
      permissions: selectedPermissions,
    });
  };

  async function handleCreateAgentSubmit(data: ICreateAgentDTO) {
    const res = await createAgentController(data);
    if (res) {
      onCreate(res);
      addAgentPermissions(res.user_id);
    }
    onClose();
  }

  return (
    <ModalContent open={isOpen} onClose={onClose} borderTop hasLogo>
      <ModalTitle>Criar Vendedor</ModalTitle>
      <form
        action="post"
        onSubmit={handleSubmit(handleCreateAgentSubmit)}
        className={styles['form-agent']}
      >
        <div className={styles.container}>
          <Controller
            control={control}
            name="document"
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <InputComponent
                label="CPF*"
                name={name}
                onChange={onChange}
                value={maskCpfCnpj(value)}
                variant={error && 'danger'}
                hint={error?.message}
                labelSize="lg"
              />
            )}
          />
          <Controller
            control={control}
            name="name"
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <InputComponent
                label="Nome*"
                name={name}
                onChange={onChange}
                value={value}
                variant={error && 'danger'}
                hint={error?.message}
                labelSize="lg"
              />
            )}
          />

          <Controller
            control={control}
            name="phone"
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <InputComponent
                label="Telefone*"
                name={name}
                onChange={onChange}
                value={maskPhone(value as string)}
                variant={error && 'danger'}
                hint={error?.message}
                labelSize="lg"
              />
            )}
          />
          <div className={styles['pass-container']}>
            <span>Senha Padrão</span>
            <InputComponent
              name="defaultPassword"
              disabled
              value="123456"
              style={{
                border: '2px solid #263238',
                opacity: '0.9',
                cursor: 'text',
              }}
            />
          </div>
        </div>
        <div className={styles.permissionsWrapper}>
          <h3>Permissões</h3>
          <ul className={styles.permissionsList}>
            {/* {agentPermissions.map(permission => (
            <li key={permission.id_permission}>
              <Checkbox
                label={permission.name}
                name={permission.id_permission}
                value={permission.id_permission}
                checked={
                  !!selectedPermissions.find(
                    perm => permission.id_permission === perm,
                  )
                }
                handleChange={e => {
                  const { checked, value } = e.target;
                  if (checked) {
                    setSelectedPermissions(previous => [...previous, value]);
                  } else {
                    setSelectedPermissions(previous =>
                      previous.filter(perm => perm !== value),
                    );
                  }
                }}
              />
              <span>{permission.description}</span>
            </li>
          ))} */}
            <li>
              <Checkbox
                label="Acesso a cotações"
                name="quotation"
                value="quotation"
                checked={
                  selectedPermissions.includes('CREATE_QUOT') &&
                  selectedPermissions.includes('ANSWER_QUOT')
                }
                handleChange={e => {
                  const { checked, value } = e.target;
                  if (checked) {
                    // setSelectedPermissions(previous => [...previous, value]);
                    setSelectedPermissions(['CREATE_QUOT', 'ANSWER_QUOT']);
                  } else {
                    setSelectedPermissions([]);
                    // setSelectedPermissions(previous =>
                    //   previous.filter(perm => perm !== value),
                    // );
                  }
                }}
              />
            </li>
          </ul>
        </div>

        <div className={styles['info-box']}>
          <WarningCircle size={24} />
          <span>O vendedor poderá modificar sua senha no primeiro acesso</span>
        </div>

        <div className={styles.actions}>
          <ButtonComponent
            text="Cancelar"
            variant="outline"
            onClick={onClose}
          />
          <ButtonComponent
            text="salvar"
            type="submit"
            variantStyle="success"
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </ModalContent>
  );
};

/**
 * async () => {
            const res = await createAgentController(getValues());
            if (res) {
              onCreate(res);
              addAgentPermissions(res.user_id);
            }
            onClose();
          }
 */
