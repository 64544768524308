import { configureStore } from '@reduxjs/toolkit';
import { conversationReducer } from './features/conversation/conversation-slice';
import { dashboardReducer } from './features/dashboard/dashboard-slice';
import { orderServicesReducer } from './features/orderService/orderService-slice';
import { budgetReducer } from './features/budget/budget-slice';
import { PartnersReducer } from './features/partners/partners-slice';
import notificationSlice from './features/Notification/notification-slice';
import { openModalReducer } from './features/modalLanginPage/openModal';
import { colorActiveHeaderLanding } from './features/modalLanginPage/color';
import { quotationReducer } from './features/quotation/quotation-reply-slice';
import { answerQuotationReducer } from './features/answerQuotation/answer-quotation';

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    conversations: conversationReducer,
    orderServices: orderServicesReducer,
    budget: budgetReducer,
    partners: PartnersReducer,
    notifications: notificationSlice,
    openModalRegister: openModalReducer,
    activeColor: colorActiveHeaderLanding,
    quotationReducer,
    answerQuotationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
