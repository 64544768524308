import { useCallback, useEffect, useMemo, useState } from 'react';
import Lottie from 'lottie-react';
import { Button } from '../../../../../../../../shared/Button';
import { Companies } from './steps/Companies';
import styles from './add-company-modal.module.css';
import creatingAnimation from '../../../../../../../../assets/roda.json';
import errorAnimation from '../../../../../../../../assets/errorLottie.json';
import { WorkshopList, useSendQuotation } from './useSendQuotation';
import { useQuotationForm } from './useQuotationForm';
import { AlertMessage } from '../../../../../../../../shared/AlertMessage';
import { Quotation } from '../../../../../../../../@types/interface';
import { useResponsive } from '../../../../../../../../hooks/useResponsive';
import { useCreateQuotation } from './useCreateQuotation';
import {
  ModalContent,
  ModalTitle,
} from '../../../../../../Finance/components/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  defaultQuotation?: Quotation | null;
}

export const AddCompanyModal = ({
  isOpen,
  onClose,
  defaultQuotation = null,
}: Props) => {
  const [step, setStep] = useState<
    'companies' | 'sending' | 'success' | 'error'
  >('companies');
  const [alertMessage, setAlertMessage] = useState('');
  const { reSendQuotation } = useSendQuotation();
  const { fetchQuotation } = useCreateQuotation();
  const { resetFormValues } = useQuotationForm({ defaultQuotation });
  const { sizeMobile } = useResponsive();
  const [workshopList, setWorkshopList] = useState<WorkshopList[]>([]);
  const [previousCompanies, setPreviousCompanies] = useState<WorkshopList[]>(
    [],
  );

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const selectedWorkshops = useMemo(
    () => workshopList.filter(workshop => workshop.selected),
    [workshopList],
  );

  const handleStep = () => {
    if (step === 'companies') {
      return (
        <Companies
          setWorkshopList={setWorkshopList}
          workshopList={workshopList}
          previousCompanies={previousCompanies}
        />
      );
    }

    if (step === 'sending') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={creatingAnimation} />
        </div>
      );
    }
    if (step === 'success') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={creatingAnimation} loop={false} />
        </div>
      );
    }
    if (step === 'error') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={errorAnimation} />
        </div>
      );
    }
  };

  const handleSendQuotation = async (quotationId: string) => {
    const res = await reSendQuotation({
      quotationId,
      workshopList: [...previousCompanies, ...workshopList],
    });
    if (res) {
      setStep('success');
      handleClose();
    } else {
      setStep('error');
    }
  };

  const handleClose = () => {
    setStep('companies');
    resetFormValues();
    onClose();
  };

  const handleModalTitle = useMemo(() => {
    if (step === 'companies') return 'Adicionar Fornecedor';
    if (step === 'sending') return 'Enviando Cotação';
    if (step === 'success') return 'Cotação Criada';
    return 'Falha ao criar cotação';
  }, [step]);

  const fetchQuotationData = useCallback(
    async (idQuotation: string) => {
      const res = await fetchQuotation(idQuotation);
      if (res && res.answers_quotation) {
        setPreviousCompanies(
          res.answers_quotation.map(answer => ({
            option: {
              name: answer?.workshop?.fantasy_name ?? '',
              value: answer?.workshop?.id_workshop ?? '',
            },
            selected: true,
          })),
        );
      }
    },
    [fetchQuotation],
  );

  const ModalFooter =
    step === 'companies' && selectedWorkshops.length > 0 ? (
      <div className={styles.wrapButtonFooter}>
        <Button
          variant="register"
          style={{
            padding: '.5rem 2.5rem',
          }}
          handleClick={() => {
            setIsConfirmModalOpen(true);
          }}
        >
          Enviar
        </Button>
      </div>
    ) : null;

  useEffect(() => {
    if (defaultQuotation && defaultQuotation.answers_quotation && isOpen) {
      fetchQuotationData(defaultQuotation.id_quotation);
    }
  }, [defaultQuotation, fetchQuotationData, isOpen]);

  return (
    <ModalContent
      open={isOpen}
      onClose={handleClose}
      hasLogo
      borderTop
      full
      ContentClassName={styles.modal}
    >
      <ModalTitle>{handleModalTitle}</ModalTitle>
      <AlertMessage.Root
        isOpen={alertMessage.length !== 0}
        onClose={() => setAlertMessage('')}
      >
        <AlertMessage.Body>{alertMessage}</AlertMessage.Body>
      </AlertMessage.Root>

      <AlertMessage.Root
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        title="Confirmar Envio"
        footer={
          <AlertMessage.Footer>
            <AlertMessage.CancelButton
              onClick={() => setIsConfirmModalOpen(false)}
            />
            <AlertMessage.ConfirmButton
              onClick={async () => {
                setIsConfirmModalOpen(false);
                await handleSendQuotation(defaultQuotation?.id_quotation ?? '');
              }}
            >
              Enviar Cotação
            </AlertMessage.ConfirmButton>
          </AlertMessage.Footer>
        }
      >
        <AlertMessage.Body>
          Tem certeza que deseja enviar a cotação?
        </AlertMessage.Body>
      </AlertMessage.Root>
      {}
      {handleStep()}
      {ModalFooter}
    </ModalContent>
  );
};
