/* eslint-disable jsx-a11y/label-has-associated-control */
import { ReactNode } from 'react';
import styles from './toggle-switch.module.css';

interface Props {
  value?: boolean;
  onChange: () => void;
  icon?: ReactNode;
}

export const ToggleSwitch = ({ onChange, value = false, icon }: Props) => {
  return (
    <label className={styles.switch}>
      <input type="checkbox" checked={value} onChange={onChange} />
      <span className={styles.slider}>
        <div className={styles.thumb}>{icon}</div>
      </span>
    </label>
  );
};
