import * as yup from 'yup';
import {
  createExpense,
  deleteExpense,
  getDashboard,
  getLatestTransactions,
  getTransactions,
  getTransactionsRapport,
  updateExpense,
  getTransactionsByName,
  getTransactionsBalance,
  postLoginFinance,
  postCreatePasswordFinance,
  putSaveRemember,
  putSavePassword,
  getTransactionCodes,
  createTransactionCode,
  updateTransactionCode,
  deleteTransactionCode,
} from './model';
import {
  CreateExpenseInput,
  GetTransactionsRapportInput,
  GetTransactionsInput,
  UpdateExpenseInput,
  GetTransactionsBalanceInput,
  CreateTransactionCodeInput,
  UpdateTransactionCodeInput,
} from './interfaces';
import message from '../../../utils/message';
import { ERROR_MESSAGES } from '../../../utils/validations/errorMessages';
import { handleError } from '../../../utils/handleError';

export const createExpenseController = async (props: CreateExpenseInput) => {
  try {
    const schema = yup.object({
      type: yup.string().required(ERROR_MESSAGES.required),
      value: yup.number().required(ERROR_MESSAGES.required),
      due_date: yup.string().required(ERROR_MESSAGES.required),
      discharge_date: yup.mixed(),
      description: yup.string(),
      transaction_code_id: yup.string(),
    });
    await schema.validate(props, {
      abortEarly: false,
    });
    const res = await createExpense(props);
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    }
    message(error, 'error');
  }
};
export const updateExpenseController = async (props: UpdateExpenseInput) => {
  try {
    const schema = yup.object({
      transactionId: yup.string().required(ERROR_MESSAGES.required),
      value: yup.number().required(ERROR_MESSAGES.required),
      due_date: yup.string().required(ERROR_MESSAGES.required),
      discharge_date: yup.mixed(),
      description: yup.string(),
      transaction_code_id: yup.string(),
    });
    await schema.validate(props, {
      abortEarly: false,
    });
    const res = await updateExpense(props);
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    }
    message(error, 'error');
  }
};
export const deleteExpenseController = async (id: string) => {
  try {
    await yup.string().required().validate(id, { abortEarly: false });
    await deleteExpense(id);
  } catch (error) {
    message(error, 'error');
  }
};
export const getDashboardController = async (year: string) => {
  try {
    await yup.string().required().validate(year, { abortEarly: false });
    const res = await getDashboard(year);
    return res;
  } catch (error) {
    message(error, 'error');
  }
};
export const getTransactionsController = async (
  props: GetTransactionsInput,
) => {
  try {
    const schema = yup.object({
      year: yup.string().required(ERROR_MESSAGES.required),
      month: yup.string().required(ERROR_MESSAGES.required),
      query: yup.string(),
      sort: yup.string(),
      order: yup.string(),
    });
    await schema.validate(props, { abortEarly: false });
    const res = await getTransactions(props);
    return res;
  } catch (error) {
    handleError(error);
  }
};
export const getTransactionsRapportController = async (
  props: GetTransactionsRapportInput,
) => {
  try {
    const schema = yup.object({
      year: yup.string().required(ERROR_MESSAGES.required),
      month: yup.string().required(ERROR_MESSAGES.required),
      toMonth: yup.string(),
    });
    await schema.validate(props, { abortEarly: false });
    const res = await getTransactionsRapport(props);
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    }
    message(error, 'error');
  }
};

export const getLatestTransactionsController = async () => {
  try {
    const res = await getLatestTransactions();
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    }
    message(error, 'error');
  }
};

export const getTransactionsByNameController = async (name: string) => {
  try {
    const schema = yup.string().required();
    await schema.validate(name);
    const res = await getTransactionsByName(name);
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    }
    message(error, 'error');
  }
};
export const getTransactionsBalanceController = async (
  props: GetTransactionsBalanceInput,
) => {
  try {
    const schema = yup.object({
      initialDate: yup.string(),
      finalDate: yup.string(),
    });
    await schema.validate(props);
    const res = await getTransactionsBalance(props);
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    }
    message(error, 'error');
  }
};

export const postInternalLoginFinance = async (oldPassword: string) => {
  try {
    const schema = yup.string().required();
    await schema.validate(oldPassword);
    const res = await postLoginFinance(oldPassword);
    return res;
  } catch (error) {
    if (error instanceof Error) {
      const msg = error.message.replace(/\D/g, '');
      if (msg === '401') {
        message('Senha incorreta! Digite novamente.', 'error');
      }
      return msg;
    }
  }
};

export const postCreateInternalPasswordFinance = async (password: string) => {
  try {
    const schema = yup.string().required();
    await schema.validate(password);
    const res = await postCreatePasswordFinance(password);
    return res;
  } catch (error) {
    message(error, 'error');
    if (error instanceof Error) {
      const msg = error.message.replace(/\D/g, '');
      return msg;
    }
  }
};

export const putSaveRememberFinance = async (acessWithoutPassword: boolean) => {
  try {
    const schema = yup.string().required();
    await schema.validate(acessWithoutPassword);
    const res = await putSaveRemember(acessWithoutPassword);
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    }
    message(error, 'error');
  }
};

export const putSaveNewPassword = async (
  oldPassword: string,
  newPassword: string,
) => {
  try {
    const schema = yup.object().shape({
      oldPassword: yup.string().required(ERROR_MESSAGES.required),
      newPassword: yup.string().required(ERROR_MESSAGES.required),
    });
    await schema.validate({ oldPassword, newPassword });
    const res = await putSavePassword(oldPassword, newPassword).then(() => {
      message(`Senha Atualizada com Sucesso`, 'success');
    });
    return res;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      message(error.errors[0], 'warning');
    }
    message(error, 'error');
  }
};

export const getTransactionCodesController = async () => {
  try {
    const res = await getTransactionCodes();
    return res;
  } catch (error) {
    handleError(error);
  }
};
export const createTransactionCodeController = async (
  props: CreateTransactionCodeInput,
) => {
  try {
    const schema = yup.object().shape({
      name: yup.string().required(),
    });

    await schema.validate(props, {
      abortEarly: true,
    });
    const res = await createTransactionCode({
      ...props,
    });
    return res;
  } catch (error) {
    handleError(error);
  }
};
export const updateTransactionCodeController = async (
  props: UpdateTransactionCodeInput,
) => {
  try {
    const schema = yup.object().shape({
      name: yup.string().required(),
      transaction_code_id: yup.string().required(),
    });

    await schema.validate(props, {
      abortEarly: true,
    });
    const res = await updateTransactionCode({
      ...props,
    });
    return res;
  } catch (error) {
    handleError(error);
  }
};
export const deleteTransactionCodeController = async (
  transactionCodeId: string,
) => {
  try {
    await deleteTransactionCode(transactionCodeId);
    return true;
  } catch (error) {
    handleError(error);
  }
};
