export const normalizeStrings = (str: string | null | undefined) => {
  const normalized = (str ?? '')
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, ''); // Remove caracteres acentuados

  // Substitui espaços e caracteres especiais por espaços
  const withoutSpecialChars = normalized.replace(/[^a-zA-Z0-9]/g, ' ');

  // Remove espaços extras e converte de volta para minúsculas
  const finalValue = withoutSpecialChars
    .replace(/\s+/g, ' ')
    .trim()
    .toLowerCase();

  return finalValue;
};
