import styled from 'styled-components';
import pixel from '../../../styles/size';

interface Props {
  width: number;
}

export const Container = styled.main`
  width: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const SpanMsg = styled.span`
  color: #d97904;
  background: #fff02f33;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0.5em;
  font-weight: bold;
  width: 100%;
  .icon {
    margin-left: 15px;
    animation: animate 3s ease-in-out infinite;

    @keyframes animate {
      0% {
        transform: scale(1.1);
      }
      50% {
        transform: scale(0.9);
      }
      100% {
        transform: scale(1.1);
      }
    }
  }
`;

export const Label = styled.div<Props>`
  font-size: ${pixel(14)};
  color: #5e5e5e;
  width: ${props => `${pixel(props.width)}`};
`;

export const Input = styled.input`
  padding: ${pixel(8)} ${pixel(10)};
  height: ${pixel(130)};
  border-radius: ${pixel(4)};
  font-size: ${pixel(14)};
  width: 100%;

  border: 2px solid #c1c0c0;

  &:focus-visible {
    border: ${pixel(2)} solid #c1c0c0;
  }

  ::-webkit-input-placeholder {
    color: #c1c0c0;
  }
`;

export const SectionButton = styled.div`
  width: 100%;
  height: ${pixel(120)};

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const Button = styled.button`
  padding: ${pixel(10)};
  color: white;
  width: ${pixel(140)};
  font-size: ${pixel(12)};

  background: var(--green001);
  border: 0;
  border-radius: ${pixel(6)};

  &:hover {
    opacity: 0.8;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${pixel(15)};
  align-items: center;

  input[type='file'] {
    display: none;
  }
`;

export const ListRow = styled.div`
  margin-top: ${pixel(20)};
  display: flex;
  flex-direction: column;
  gap: ${pixel(20)};
`;

export const ButtonImage = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: ${pixel(10)};
  color: white;
  height: ${pixel(32)};
  font-size: ${pixel(12)};
  cursor: pointer;

  background: #0fd16a;
  border: 0;

  &:hover {
    opacity: 0.8;
  }
`;

export const InputImage = styled.div`
  height: ${pixel(45)};
  padding: ${pixel(8)} ${pixel(10)};

  border: ${pixel(2)} solid #c1c0c0;
  border-radius: ${pixel(4)};
  color: gray;
  font-size: ${pixel(10)};

  display: flex;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${pixel(225)};
`;
