import { useEffect, useState } from 'react';
import './styles.css';
import { WifiHigh, WifiX } from 'phosphor-react';

export const NetworkStatus = () => {
  const [connectionStatus, setConnectionStatus] = useState('');

  useEffect(() => {
    const handleOnline = () => {
      setConnectionStatus('online');
      setTimeout(() => setConnectionStatus(''), 3000);
    };
    const handleOffline = () => setConnectionStatus('offline');

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <div id="network-status" className={connectionStatus}>
      {connectionStatus === 'online' && (
        <>
          <WifiHigh size="2rem" color="#fff" weight="bold" />
          <p>Você está conectado à Internet.</p>
        </>
      )}

      {connectionStatus === 'offline' && (
        <>
          <WifiX size="2rem" color="#fff" weight="bold" />
          <p>Você está desconectado. Verifique sua conexão.</p>
        </>
      )}
    </div>
  );
};
