import { useMemo } from 'react';
import { ItemAnswerQuotation } from '../../../../../../../@types/interface';
import styles from './product.module.css';

interface Props {
  itemAnswerQuotation: ItemAnswerQuotation;
  answered?: boolean;
  approved?: boolean;
}

export const Product = ({
  itemAnswerQuotation,
  answered = false,
  approved = false,
}: Props) => {
  const quantityAsked = useMemo(() => {
    if (!itemAnswerQuotation?.quantity_asked) return 0;
    return itemAnswerQuotation.quantity_asked;
  }, [itemAnswerQuotation?.quantity_asked]);

  const quantityAvailable = useMemo(() => {
    if (!itemAnswerQuotation?.quantity_available) return 0;
    return itemAnswerQuotation.quantity_available;
  }, [itemAnswerQuotation?.quantity_available]);

  const brand = useMemo(() => {
    return itemAnswerQuotation?.brand ?? '';
  }, [itemAnswerQuotation?.brand]);

  const reference = useMemo(() => {
    return itemAnswerQuotation?.reference ?? '';
  }, [itemAnswerQuotation?.reference]);

  const total = useMemo(() => {
    return itemAnswerQuotation?.total
      ? itemAnswerQuotation.total.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : '---';
  }, [itemAnswerQuotation?.total]);

  return (
    <div className={styles.container}>
      <ul className={styles.info}>
        <li>
          <span>Marca</span>
          <span>{brand}</span>
        </li>
        <li>
          <span>Referência</span>
          <span>{reference}</span>
        </li>
      </ul>
      <div className={styles['values-wrapper']}>
        <div className={styles.quantity}>
          {answered && !approved ? (
            <>
              <span>Qtd. Disponível</span>
              <span>{quantityAvailable}</span>
            </>
          ) : (
            <>
              <span>{approved ? 'Qtd. Pedida' : 'Qtd. Solicitada'}</span>
              <span>{quantityAsked}</span>
            </>
          )}
        </div>
        <div className={styles.quantity}>
          <span>Valor Unitário</span>
          <span>R$ {total}</span>
        </div>
      </div>
    </div>
  );
};
