import { ComponentPropsWithoutRef, forwardRef } from 'react';
import styles from './table-row.module.css';

type TableRowProps = ComponentPropsWithoutRef<'tr'>;

export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ children, ...props }, ref) => (
    <tr
      ref={ref}
      {...props}
      className={`${styles['row-item']} ${props.className}`}
    >
      {children}
    </tr>
  ),
);
