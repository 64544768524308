import { Warning } from '@phosphor-icons/react';
import { Modal } from '../../../../../../../../shared/Modal';
import { Button } from '../../../../../../../../shared/Button';
import styles from './confirm-modal.module.css';

interface Props {
  container: Element | DocumentFragment;
  items: number[];
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmModal = ({
  container,
  items,
  onClose,
  onConfirm,
}: Props) => {
  const handleMessage = () => {
    if (items.length > 1) {
      return `Items ${items.join(
        ',',
      )} não preenchidos, deseja enviar mesmo assim?`;
    }
    return `Item ${items[0]} não preenchido, deseja enviar mesmo assim?`;
  };

  return (
    <Modal
      isOpen={items.length > 0}
      title="Confirmação de Resposta"
      onClose={onClose}
      container={container}
      footer={
        <div className={styles.actions}>
          <Button
            handleClick={onClose}
            variant="cancel"
            style={{
              fontSize: '1.5rem',
              width: '35%',
              padding: '0.6rem 0.85rem 0.6rem 0.75rem',
            }}
          >
            Revisar
          </Button>
          <Button
            handleClick={() => {
              onConfirm();
              onClose();
            }}
            variant="register"
            style={{
              fontSize: '1.5rem',
              width: '35%',
            }}
          >
            Confirmar
          </Button>
        </div>
      }
    >
      <div className={styles.container}>
        As informações sobre os itens da cotação serão respondidas e enviadas
        para a empresa solicitante, revise se necessário os itens e clique em
        confirmar para concluir a sua resposta.
        <p className={styles.warning}>
          <Warning size={32} color="#9D1816" weight="bold" />
          {handleMessage()}
        </p>
      </div>
    </Modal>
  );
};
