import axios from 'axios';
import api from '../../../services/api/api';
import {
  AddressResponse,
  UpdateUserPasswordInput,
  UpdateWorkshopInput,
  WorkshopResponse,
} from './interface';

export const getWorkshopData = async () => {
  const { data } = await api.get<WorkshopResponse>('/user/workshop');
  return data;
};

export const getAddress = async (zip: string) => {
  const { data } = await axios.get<AddressResponse>(
    `https://viacep.com.br/ws/${zip}/json/`,
  );
  return data;
};

export const updateWorkshopData = async (props: UpdateWorkshopInput) => {
  const { data } = await api.put<WorkshopResponse>('/workshop', {
    ...props,
  });
  return data;
};

export const updateUserPassword = async (props: UpdateUserPasswordInput) => {
  const { data } = await api.put('/user/self', {
    ...props,
  });
  return data;
};

export const updateClientCode = async (client_code: string) => {
  const { data } = await api.put<WorkshopResponse>('/client_code', {
    client_code,
  });
  return data;
};
