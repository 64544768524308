/* eslint-disable @typescript-eslint/no-empty-function */
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { Store } from 'react-notifications-component';
import styles from './header.module.css';
import { BackButton } from '../BackButton';

interface HeaderProps {
  title: string;
  page?: number;
  setPage?: Dispatch<SetStateAction<number>>;
  onClick?: () => void;
  path?: string;
}

export const Header = ({
  title,
  page,
  setPage,
  onClick = () => {},
  path,
}: HeaderProps) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    // if (title === 'Avaliações') {
    //   navigate('/home');
    //   return;
    // }

    if (page && setPage) {
      if (page > 0) setPage(page - 1);
      else {
        navigate(-1);
        onClick();
      }
    } else {
      if (path) navigate(path);
      else navigate(-1);
      onClick();
    }
  };
  const handleIconClick = () => {
    handleNavigation();
    Store.removeAllNotifications();
  };
  return (
    <header className={styles.header}>
      <BackButton handleIconClick={handleIconClick} />
      <h1>{title}</h1>
    </header>
  );
};
