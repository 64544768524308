import { create } from 'zustand';

interface ResumeModalStore {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const useResumeModal = create<ResumeModalStore>(set => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}));

export default useResumeModal;
