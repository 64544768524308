import api from '../../../services/api/api';
import {
  CreateExpenseInput,
  GetTransactionsRapportInput,
  GetTransactionsInput,
  UpdateExpenseInput,
  GetTransactionsBalanceInput,
  TransactionResponse,
  DashboardResponse,
  LoginFinance,
  TransactionCodeResponse,
  CreateTransactionCodeInput,
  UpdateTransactionCodeInput,
} from './interfaces';
import { CreateIncomeDTO } from './pages/Transactions/utils/DTOs/createIncomeDTO';
import { updateDischargeDateDTO } from './pages/Transactions/utils/DTOs/updateDischargeDateDTO';
import { UpdateTransactionDTO } from './pages/Transactions/utils/DTOs/updateTransactionDTO';

export const createExpense = async (
  props: CreateExpenseInput | CreateIncomeDTO,
) => {
  const { data } = await api.post<TransactionResponse>('/transaction', props);

  return data;
};

export const UpdateDischargeDate = async (props: UpdateExpenseInput) => {
  const { data: response } = await api.put<TransactionResponse>(
    '/transaction',
    {
      ...props,
    },
  );
  return response;
};

export const updateExpense = async (
  props: UpdateExpenseInput | UpdateTransactionDTO,
) => {
  const { data } = await api.put<TransactionResponse>('/transaction', props);
  return data;
};

export const deleteExpense = async (id: string) => {
  await api.delete(`/transaction/${id}`);
  return '';
};

export const getDashboard = async (year: string) => {
  const { data } = await api.get<DashboardResponse>(
    `/transaction/dashboard?year=${year}`,
  );
  return data;
};

export const getTransactions = async (props: GetTransactionsInput) => {
  let queries = `year=${props.year}&month=${props.month}`;

  // if (props.limit && props.page) {
  //   queries += `&limit=${props.limit}&page=${props.page}`;
  // } else {
  //   queries += `&limit=999&page=1`;
  // }

  if (props.sort && props.order) {
    queries += `&sort=${props.sort}&order=${props.order}`;
  }

  if (props.query) {
    queries += `&query=${props.query}`;
  }

  const { data } = await api.get<TransactionResponse[]>(
    `/transaction?${queries}`,
  );
  return data;
};

export const getTransactionsRapport = async (
  props: GetTransactionsRapportInput,
) => {
  let queries = `year=${props.year}&month=${props.month}`;
  if (props.toMonth) {
    queries += `&toMonth=${props.toMonth}`;
  }
  const { data } = await api.get<TransactionResponse[]>(
    `/transaction/rapport?${queries}`,
  );
  return data;
};

export const getLatestTransactions = async () => {
  const { data } = await api.get<TransactionResponse[]>(
    '/transaction/latest?page=1&limit=2',
  );
  return data;
};

export const getTransactionsByName = async (name: string) => {
  const { data } = await api.get<TransactionResponse[]>(
    `/transaction/name?name=${name}`,
  );
  return data;
};

export const getTransactionsBalance = async ({
  finalDate,
  initialDate,
}: GetTransactionsBalanceInput) => {
  let queryUrl = '';
  if (initialDate) {
    queryUrl += `?initialDate=${initialDate}`;
    if (finalDate) {
      queryUrl += `&finalDate=${finalDate}`;
    }
  }
  const { data } = await api.get<TransactionResponse[]>(
    `/transaction/period${queryUrl}`,
  );

  return data;
};

export const postLoginFinance = async (password: string) => {
  const { data } = await api.post<LoginFinance>('auth/workshop/internal', {
    password,
  });
  return data;
};

export const postCreatePasswordFinance = async (password: string) => {
  if (password) {
    const data = {
      password,
    };
    const res = await api.post<LoginFinance>(
      'workshop/internal_password',
      data,
    );
    return res;
  }
};

export const putSaveRemember = async (acessWithoutPassword: boolean) => {
  const data = {
    remember_me: acessWithoutPassword,
  };
  const res = await api.put<LoginFinance>('workshop/remember_me', data);
  return res;
};

export const putSavePassword = async (
  oldPassword: string,
  newPassword: string,
) => {
  if (oldPassword && newPassword) {
    const data = {
      current_password: oldPassword,
      new_password: newPassword,
    };

    const res = await api.put<LoginFinance>('workshop/internal_password', data);
    return res;
  }
};

export const getTransactionCodes = async () => {
  const { data } = await api.get<TransactionCodeResponse[]>(
    `/transaction/code`,
  );
  return data;
};

export const createTransactionCode = async (
  props: CreateTransactionCodeInput,
) => {
  const { data } = await api.post<TransactionCodeResponse>(
    `/transaction/code`,
    {
      ...props,
    },
  );
  return data;
};
export const updateTransactionCode = async (
  props: UpdateTransactionCodeInput,
) => {
  const { data } = await api.put<TransactionCodeResponse>(`/transaction/code`, {
    ...props,
  });
  return data;
};

export const deleteTransactionCode = async (transactionCodeId: string) => {
  const { data } = await api.delete(`/transaction/code/${transactionCodeId}`);
  return data;
};
