import { useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import { createPortal } from 'react-dom';
import styles from './feedback-modal.module.css';

import loadingAnimation from '../../assets/searchLottie.json';
import sendingAnimation from '../../assets/roda.json';

interface Props {
  isOpen: boolean;
  variant?: 'loading' | 'sending' | 'success' | 'error';
}

export const FeedbackModal = ({ isOpen, variant = 'loading' }: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isOpen]);

  if (isOpen) {
    return createPortal(
      <div className={styles.overlay}>
        <div className={styles.content} ref={modalRef}>
          <div className={styles.body} style={{ background: '#f3f3f3' }}>
            {variant === 'loading' && (
              <Lottie animationData={loadingAnimation} />
            )}
            {variant === 'sending' && (
              <Lottie animationData={sendingAnimation} />
            )}
          </div>
        </div>
      </div>,
      document.body,
    );
  }
  return null;
};
