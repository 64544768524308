import {
  CreateAgent,
  UpdateAgent,
  AgentResponse,
  AgentPermissionsResponse,
  AddAgentPermissionsInput,
  RemoveAgentPermissionsInput,
} from './interface';
import api from '../../../services/api/api';

export const createAgent = async (props: CreateAgent) => {
  const { data } = await api.post<AgentResponse>('/agent', props);
  return data;
};

export const updateAgent = async (props: UpdateAgent) => {
  const { data } = await api.put<AgentResponse>('/agent', props);
  return data;
};

export const getAgent = async () => {
  const { data } = await api.get<AgentResponse[]>(`/agent`);
  return data;
};

export const getAgentById = async (idAgent: string) => {
  const { data } = await api.get<AgentResponse>(`/agent/${idAgent}`);
  return data;
};

export const getAgentPermissions = async () => {
  const { data } = await api.get<AgentPermissionsResponse[]>(`/permission`);
  return data;
};

export const addAgentPermissions = async (props: AddAgentPermissionsInput) => {
  const { data } = await api.put<AgentResponse[]>(`/permission/add`, {
    ...props,
  });
  return data;
};

export const removeAgentPermissions = async (
  props: RemoveAgentPermissionsInput,
) => {
  const { data } = await api.put<AgentResponse[]>(`/permission/remove`, {
    ...props,
  });
  return data;
};
