export const saveObjectLocally = (key: string, object: any) => {
  try {
    const serializedObject = JSON.stringify(object);
    localStorage.setItem(key, serializedObject);
    return true;
  } catch (error) {
    console.error('Erro ao salvar o objeto localmente:', error);
    return false;
  }
};

export const getObjectFromLocal = (key: string) => {
  try {
    const serializedObject = localStorage.getItem(key);
    if (serializedObject) {
      const parsedObject = JSON.parse(serializedObject);
      return parsedObject;
    }
    return null;
  } catch (error) {
    console.error('Erro ao resgatar o objeto localmente:', error);
    return null;
  }
};

export const removeObjectFromLocal = (key: string) => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.error('Erro ao remover o objeto local:', error);
    return false;
  }
};
