import { Vehicle } from '../../../@types/interface';
import { User } from '../../../pages/common/History/interfaces';
import api from '../../api/api';

export interface VehicleResponse extends Vehicle {
  user_id: string | null;
  locator_id: string | null;
  transferred: boolean;
  user?: User;
  km_day_editable: boolean;
}

export const fetchVehicleData = async (licensePlate: string) => {
  const { data } = await api.get<VehicleResponse>(
    `/vehicle/licensePlate/api/${licensePlate}`,
  );
  return data;
};
