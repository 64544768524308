export const handleHtmlToPdf = (html: string, title = 'Imprimir') => {
  const printWindow = window.open('', '', 'width=1000,height=600');
  if (printWindow) {
    printWindow.document.open();
    printWindow.document.write(
      `<html><head><title>${title}</title></head><body>`,
    );
    printWindow.document.write(html);
    printWindow.document.write('</body>');
    printWindow.document.write(
      '<script>window.onload = function() { window.print(); }</script></html>',
    );
    printWindow.document.close();
  }
};
