import { create } from 'zustand';
import { AnswerQuotation, Quotation } from '../../../../../@types/interface';

interface SelectedQuotationStore {
  quotation: Quotation | null;
  onSelect: (quotation: Quotation) => void;
  onSetAnswers: (answers_quotation: AnswerQuotation[]) => void;
  onClear: () => void;
}

const useSelectedQuotation = create<SelectedQuotationStore>((set, get) => ({
  quotation: null,
  onSelect: async (quotation: Quotation) => {
    console.log('seleção');
    set({ quotation });
  },
  onClear: () => {
    console.log('limpeza');
    set({ quotation: null });
  },
  onSetAnswers: (answers_quotation: AnswerQuotation[]) => {
    const currentQuotation = get().quotation;

    if (!currentQuotation) return;

    set({ quotation: { ...currentQuotation, answers_quotation } });
  },
}));

export default useSelectedQuotation;
