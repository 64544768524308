import { Root } from './components/Root';
import { Body } from './components/Body';
import { Head } from './components/Head';
import { Row } from './components/Row';
import { SubRow } from './components/SubRow';
import { HeadItem } from './components/HeadItem';
import { RowItem } from './components/RowItem';
import { DeleteButton } from './components/DeleteButton';
import { EditButton } from './components/EditButton';
import { ViewButton } from './components/ViewButton';
import { WhatsappButton } from './components/WhatsappButton';

export const ListTable = {
  Root,
  Head,
  Body,
  Row,
  SubRow,
  HeadItem,
  RowItem,
  DeleteButton,
  EditButton,
  ViewButton,
  WhatsappButton,
};
