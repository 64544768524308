import { ProductTable } from '../../../../../../../../../../shared/ProductTable';
import styles from './product-table-header.module.css';

export const ProductTableHeader = () => {
  return (
    <div className={styles.wrapProductTableHeader}>
      <ProductTable.Header
        style={{ gridTemplateColumns: '0.25fr 1fr .5fr 1fr' }}
      >
        <span>Item</span>
        <span>Descrição</span>
        <span>Qtd. </span>
        <span style={{ paddingRight: '1.5rem' }}>ref/marca</span>
      </ProductTable.Header>
    </div>
  );
};
