import { create } from 'zustand';

interface AddCompanyModalStore {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const useAddCompanyModal = create<AddCompanyModalStore>(set => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}));

export default useAddCompanyModal;
