/* eslint-disable no-useless-escape */
export const validateLicensePlate = (licensePlate: string) => {
  if (!licensePlate) return false;

  const formattedLicensePlate = licensePlate
    .replace(/\s|-/g, '')
    .toLocaleUpperCase();

  const brazilianPlatePattern = /^[A-Za-z]{3}\d{4}$/;
  const mercosulPlatePattern = /^[A-Za-z]{3}\d{1}[A-Za-z]{1}\d{2}$/;

  return (
    brazilianPlatePattern.test(formattedLicensePlate) ||
    mercosulPlatePattern.test(formattedLicensePlate)
  );
};
