/* eslint-disable react/no-array-index-key */
import styles from './list-card.module.css';

interface Props {
  title: string;
  items: {
    title: string;
    description: string;
  }[];
  describe?: string;
  img_url: string;
}

export const ListCard = ({ items, describe, title, img_url }: Props) => {
  return (
    <div className={styles.container}>
      <img src={img_url} alt={title} />
      <div className={styles.content}>
        <h1>{title}</h1>
        <ul className={styles.list}>
          {items.map((item, index) => (
            <li key={index}>
              <p>
                <h3>{item.title}: </h3>
                {item.description}
              </p>
            </li>
          ))}
        </ul>
        {describe && (
          <p>
            <b>{describe}</b>
          </p>
        )}
      </div>
    </div>
  );
};
