import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface showRegister {
  show: boolean;
}

const openModalSlice = createSlice({
  name: 'openModal',
  initialState: { show: false },
  reducers: {
    setOpen: (state, action: PayloadAction<showRegister>) => {
      return action.payload;
    },
  },
});

export const { setOpen } = openModalSlice.actions;

export const openModalReducer = openModalSlice.reducer;
