import { useCallback, useEffect, useRef, useState } from 'react';
import { CaretDown, CaretUp } from 'phosphor-react';
import styles from './filter-menu.module.css';

export interface FilterMenuOptions {
  label: string;
  value: string;
}

interface Props {
  handleChange: (value: string) => void;
  options: FilterMenuOptions[];
  defaultValue?: string;
}

export const FilterMenu = ({
  handleChange,
  options,
  defaultValue = '',
}: Props) => {
  const customSelectRef = useRef<HTMLDivElement>(null);
  const customOptionsRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    options.find(option => option.value === defaultValue)?.label ||
      'Aplicar Filtro',
  );

  const handleOptionClick = useCallback(
    (value: string, label: string) => {
      setSelectedValue(label);
      handleChange(value);
      setIsOpen(false);
    },
    [handleChange],
  );

  useEffect(() => {
    const currentCustomSelectRef = customSelectRef.current;
    const handleSelectClick = () => setIsOpen(!isOpen);

    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLDivElement;
      const labelValue = target.innerText ?? '';
      const inputValue =
        (target.querySelector('input[type="hidden"]') as HTMLInputElement)
          ?.value ?? '';

      handleOptionClick(inputValue, labelValue);
    };
    const currentCustomOptionsRef = customOptionsRef.current;
    if (currentCustomOptionsRef) {
      currentCustomOptionsRef.addEventListener('click', handleClick);
    }

    if (currentCustomSelectRef) {
      currentCustomSelectRef.addEventListener('click', handleSelectClick);
    }

    return () => {
      currentCustomSelectRef?.removeEventListener('click', handleSelectClick);
      if (currentCustomOptionsRef) {
        currentCustomOptionsRef.removeEventListener('click', handleClick);
      }
    };
  }, [handleOptionClick, isOpen]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      customSelectRef.current &&
      !customSelectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={styles.container}>
      <div className={styles.customSelect} ref={customSelectRef}>
        <div className={styles.customSelectTrigger}>
          <span>{selectedValue}</span>
          <div className={styles.arrow}>
            {isOpen ? <CaretUp size={20} /> : <CaretDown size={20} />}
          </div>
        </div>
        {isOpen && (
          <div className={styles.customOptions} ref={customOptionsRef}>
            {options.map(option => (
              <span key={option.value} className={styles.customOption}>
                <input type="hidden" name={option.value} value={option.value} />
                {option.label}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
