import { AnswerQuotation } from '../../../../../../../../@types/interface';
// import { useResponsive } from '../../../../../../../../hooks/useResponsive';
import { DesktopList } from './Desktop';
// import { MobileList } from './Mobile';

interface Props {
  answerList: AnswerQuotation[];
  handleRowClick: (answer: AnswerQuotation) => void;
  onScroll: () => void;
  isLoading: boolean;
  isEmpty?: boolean;
  notFound?: boolean;
}

export const AnswerList = (answerListProps: Props) => {
  // const { sizeMobile } = useResponsive();

  // if (sizeMobile) return <MobileList {...answerListProps} />;

  return <DesktopList {...answerListProps} />;
};
