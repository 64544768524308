import ReactDOMServer from 'react-dom/server';

import { QRCodeSVG } from 'qrcode.react';
import { maskCpfCnpj } from '../../../services/helpers/mask';
import { AgentResponse } from '../../../pages/gasStation/AgentManagement/interface';
import logo from '../../../assets/icons/autocenter_logo.svg';
import { Container } from './html/container';

export const agentQRcodePdf = (data: AgentResponse) => {
  const generateQRCode = () => {
    if (data && data.code.length > 0) {
      return ReactDOMServer.renderToString(
        <QRCodeSVG
          value={data.code}
          size={200}
          imageSettings={{
            src: logo,
            height: 24,
            width: 24,
            excavate: true,
          }}
        />,
      );
    }
    return '';
  };

  return Container({
    cpf: maskCpfCnpj(data.user.document),
    name: data.user.name,
    content: `
      ${generateQRCode()}
    `,
  });
};
