import { useCallback, useEffect, useMemo, useState } from 'react';
import { AnswerQuotation } from '../../../../../../@types/interface';
import { Modal } from '../../../../../../shared/Modal';
import { BoxInfo } from '../BoxInfo';
import styles from './view-answer-quotation-modal.module.css';
import { AnswerQuotationItems } from '../AnswerQuotationItems';
import { useApproveQuotation } from '../../../autoshop/pages/ApprovedQuotations/hooks/useApproveQuotation';
import { ConfirmModal } from '../../../autoshop/pages/ApprovedQuotations/components/ConfirmModal';
import { useFetchAnswer } from '../../hooks/useFetchAnswer';
import { useAnswerQuotation } from '../../../../../../contexts/answerQuotationContext';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  answer: AnswerQuotation | null;
  isOwner?: boolean;
  isConfirming?: boolean;
  answerId?: string;
}

export const ViewAnswerQuotationModal = ({
  isOpen,
  onClose,
  answer,
  isOwner = false,
  isConfirming = false,
  answerId = '',
}: Props) => {
  const { fetchAnswer, isLoading } = useFetchAnswer();
  const { updateAnswerQuotations } = useAnswerQuotation();

  const [fetchedAnswer, setFetchedAnswer] = useState(answer);

  const getQuotation = useCallback(async () => {
    if (!answer?.id_answer_quotation && answerId.length === 0) return;
    const res = await fetchAnswer(
      answerId.length > 0 ? answerId : answer?.id_answer_quotation ?? '',
    );
    if (res) {
      setFetchedAnswer(res);
      updateAnswerQuotations(res);
    }
  }, [answer?.id_answer_quotation, answerId, fetchAnswer]);

  useEffect(() => {
    if (isOpen) {
      getQuotation();
    }

    return () => {
      setFetchedAnswer(null);
    };
  }, [isOpen, getQuotation]);

  const {
    handleConfirmQuotation,
    handleConfirmModal,
    isConfirmModalOpen,
    isConfirmingQuotation,
  } = useApproveQuotation({
    onClose,
    quotationAnswer: answer,
    isOpen,
  });

  const ModalFooter = (
    <div className={styles.actions}>
      <button type="button" onClick={onClose}>
        Voltar
      </button>
      {isConfirming && (
        <button
          type="button"
          onClick={() => handleConfirmModal('open')}
          className={styles['confirm-btn']}
        >
          Confirmar
        </button>
      )}
    </div>
  );

  const handleSubtitleText = useMemo(() => {
    if (isConfirming) {
      return `cotação aguardando confirmação`;
    }
    if (!fetchedAnswer) return '';

    if (fetchedAnswer?.rejected) {
      return 'Proposta rejeitada pelo comprador';
    }
    if (fetchedAnswer?.rejected_by_reciever) {
      return 'Rejeitada pelo fornecedor';
    }
    if (fetchedAnswer?.answered && !fetchedAnswer?.confirmed)
      return 'Aguardando avaliação do comprador';

    if (!fetchedAnswer?.answered) return 'aguardando proposta';
    if (!fetchedAnswer?.finished) return 'aguardando confirmação';

    return isOwner
      ? 'cotação finalizada'
      : `cotação de ${fetchedAnswer?.quotation?.workshop?.fantasy_name || ''}`;
  }, [
    fetchedAnswer?.answered,
    fetchedAnswer?.finished,
    fetchedAnswer?.quotation?.workshop?.fantasy_name,
    fetchedAnswer?.rejected,
    fetchedAnswer?.rejected_by_reciever,
    isConfirming,
    isOwner,
  ]);

  const subtitle = useMemo(() => {
    const color =
      fetchedAnswer?.rejected || fetchedAnswer?.rejected_by_reciever
        ? 'var(--error)'
        : '';

    return (
      <h3
        style={{
          color,
          textTransform: 'uppercase',
          textAlign: 'center',
        }}
      >
        {handleSubtitleText}
      </h3>
    );
  }, [
    fetchedAnswer?.rejected,
    fetchedAnswer?.rejected_by_reciever,
    handleSubtitleText,
  ]);

  const modalTitle = useMemo(() => {
    if (isLoading) return 'buscando cotação';
    const quotationNumber = fetchedAnswer?.quotation?.QUOTATION ?? '';

    return `Visualizar cotação - Nº ${quotationNumber}`;
  }, [fetchedAnswer?.quotation?.QUOTATION, isLoading]);

  return (
    <Modal
      title={modalTitle}
      isOpen={isOpen}
      onClose={onClose}
      size="large"
      footer={ModalFooter}
      subtitle={subtitle}
      isLoading={isLoading}
    >
      <div className={styles.container}>
        <ConfirmModal
          isLoading={isConfirmingQuotation}
          isOpen={isConfirmModalOpen}
          onClose={() => handleConfirmModal('close')}
          onConfirm={handleConfirmQuotation}
        />
        <BoxInfo
          quotation={fetchedAnswer?.quotation ?? null}
          answer={fetchedAnswer}
        />
        <AnswerQuotationItems answer={fetchedAnswer} isOwner={isOwner} />
      </div>
    </Modal>
  );
};
