/* eslint-disable react/no-array-index-key */
import { Control, Controller } from 'react-hook-form';
import {
  CaretDown,
  CaretUp,
  CursorClick,
  RadioButton,
  X,
} from '@phosphor-icons/react';
import { useCallback, useMemo } from 'react';
import { Button } from '../../../../../../../../shared/Button';
import { ProductTable } from '../../../../../../../../shared/ProductTable';
import styles from './quotation-item.module.css';
import { Input } from '../../../../../../../../shared/Input';
import { FormValues, formItemQuotation } from '../../hooks/useAnswerQuotation';
import { currencyMask } from '../../../../../../../../services/helpers/mask';
import { useResponsive } from '../../../../../../../../hooks/useResponsive';
import { QUOTATION } from '../../../../../../../../constants';

interface Props {
  item: formItemQuotation;
  index: number;
  control: Control<FormValues, any>;
  isSelected: boolean;
  onClick: () => void;
  isAnswered: boolean;
  addSubItem: (index: number) => void;
  removeSubItem: (indexItem: number, indexSubitem: number) => void;
}

export const QuotationItem = ({
  index,
  item,
  control,
  isSelected,
  onClick,
  isAnswered,
  addSubItem,
  removeSubItem,
}: Props) => {
  const { sizeMobile } = useResponsive();
  const backgroundColor = useMemo(() => {
    if (isSelected && !sizeMobile) {
      return 'var(--tint_4)';
    }
    if (!isSelected && !sizeMobile) {
      return 'var(--silver)';
    }
    if (isSelected && sizeMobile) {
      return '#a5d6a7';
    }
    if (!isSelected && sizeMobile) {
      return '#eeeeee';
    }
  }, [isSelected, sizeMobile]);

  const handleFocus = useCallback(
    (data: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => {
      if (data.target instanceof HTMLInputElement) {
        const inputElement = data.target;
        const { length } = inputElement.value;
        setTimeout(() => {
          inputElement.setSelectionRange(length, length);
        }, 0);
      }
    },
    [],
  );

  const description = useMemo(
    () => item?.description ?? '---',
    [item?.description],
  );

  const informedReference = useMemo(
    () => item?.informedReference ?? '---',
    [item?.informedReference],
  );

  const quantity = useMemo(() => item?.quantity ?? '---', [item?.quantity]);

  return (
    <div className={styles['quote-item']}>
      <ProductTable.Row
        style={{
          gridTemplateColumns: 'repeat(4, 1fr)',
          justifyItems: 'flex-start',
          lineHeight: sizeMobile ? '3.3rem' : '4rem',
          backgroundColor,
        }}
        handleClick={onClick}
      >
        <span className={styles.column}>
          {isAnswered ? (
            <RadioButton size={24} weight="fill" color="#2194F3" />
          ) : (
            <RadioButton
              size={24}
              weight="fill"
              color="#FFFFFF"
              strokeWidth={5}
              stroke="black"
            />
          )}
          {index + 1}
        </span>

        <span className={styles.column}>{description}</span>

        {!sizeMobile && (
          <span className={styles.column}>{informedReference}</span>
        )}

        <span className={styles.column}>
          {!sizeMobile && <span>{quantity}</span>}
          {isSelected ? (
            <CaretUp size={24} weight="fill" color="#0B5226" />
          ) : (
            <CaretDown size={24} weight="fill" color="#0B5226" />
          )}
        </span>
      </ProductTable.Row>
      {isSelected && (
        <div className={styles['offer-container']}>
          {sizeMobile && (
            <div
              className={
                (styles['offer-reference-sub-row'], styles['space-between'])
              }
            >
              <h3 className={styles.describe}>
                Referencia: {informedReference}
              </h3>
              {sizeMobile && (
                <h3 className={styles.describe}>Qtd. solicitada: {quantity}</h3>
              )}
            </div>
          )}
          <div className={styles['offer-container-sub-row']}>
            <h3 className={styles.describe}>
              <CursorClick size={24} weight="fill" color="#0B5226" />
              Preencha com sua proposta:
            </h3>

            <ProductTable.Header
              style={{
                gridTemplateColumns: 'repeat(4, 1fr)',
                backgroundColor: '#e8f5e9',
                width: '100%',
                padding: sizeMobile ? '0.25rem .5rem' : '.25rem 2rem',
              }}
            >
              <span>Marca</span>
              <span>Referência</span>
              <span>Valor Unit.</span>
              <span>Qtd. disponível</span>
            </ProductTable.Header>

            {item.sub_items.map((items, ItemsIndex) => (
              <div className={styles.container} key={ItemsIndex}>
                <div className={styles.row}>
                  <Controller
                    control={control}
                    name={`items.${index}.sub_items.${ItemsIndex}.brand`}
                    render={({
                      field: { name, onChange, value },
                      fieldState: { error },
                    }) => (
                      <div className={styles['input-control']}>
                        <Input
                          name={name}
                          handleChange={e => {
                            onChange(e.target.value.toUpperCase());
                          }}
                          value={value?.toUpperCase()}
                          placeholder="Marca"
                          style={{
                            borderColor: error ? 'var(--error)' : '',
                          }}
                        />
                      </div>
                    )}
                  />
                  <Controller
                    control={control}
                    name={`items.${index}.sub_items.${ItemsIndex}.reference`}
                    render={({ field: { name, onChange, value } }) => (
                      <div className={styles['input-control']}>
                        <Input
                          name={name}
                          handleChange={e => {
                            onChange(e.target.value.toUpperCase());
                          }}
                          value={value?.toUpperCase() || ''}
                          placeholder="Referência"
                        />
                      </div>
                    )}
                  />

                  <Controller
                    control={control}
                    name={`items.${index}.sub_items.${ItemsIndex}.total`}
                    render={({ field: { name, onChange, value } }) => (
                      <div
                        className={`${styles['input-control']} ${styles.value}`}
                      >
                        <Input
                          name={name}
                          value={currencyMask(value)}
                          handleChange={data => {
                            const maskedValue = data.target.value
                              .replace(/[^\d]/g, '')
                              .replace(/(.{2})$/, '.$1');
                            onChange({
                              ...data,
                              target: {
                                ...data.target,
                                value: maskedValue,
                              },
                            });
                          }}
                          pattern="([0-9]+)"
                          placeholder="Preço"
                          onFocus={data => handleFocus(data)}
                        />
                      </div>
                    )}
                  />

                  <Controller
                    control={control}
                    name={`items.${index}.sub_items.${ItemsIndex}.quantity_available`}
                    render={({ field: { name, onChange, value } }) => (
                      <div className={styles['input-control']}>
                        <Input
                          name={name}
                          value={value}
                          placeholder="Qtd. Disponível"
                          handleChange={e => {
                            const maskedValue = e.target.value.replace(
                              /[^\d]/g,
                              '',
                            );
                            onChange({
                              ...e,
                              target: {
                                ...e.target,
                                value: maskedValue,
                              },
                            });
                          }}
                          pattern="([0-9]+)"
                        />
                      </div>
                    )}
                  />
                </div>
                {item.sub_items.length > 1 && (
                  <X
                    size={25}
                    weight="bold"
                    onClick={() => removeSubItem(index, ItemsIndex)}
                  />
                )}
              </div>
            ))}

            {item.sub_items.length < QUOTATION.MAX_ANSWER_SUBITEMS && (
              <Button
                handleClick={() => addSubItem(index)}
                variant="ghost"
                style={{
                  width: '100%',
                  fontSize: '0.9rem',
                }}
              >
                + adicionar nova marca
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
