import { CaretDown, CaretUp, Eye, WhatsappLogo } from 'phosphor-react';
import { useCallback, useMemo } from 'react';
import { Button } from '../../../../../../../../../shared/Button';
import { Table } from '../../../../../../../Finance/components/Table';
import styles from './desktop-list.module.css';
import { AnswerQuotation } from '../../../../../../../../../@types/interface';
import {
  dateOffset,
  formatDate,
  formatTime,
} from '../../../../../../../../../utils/dateFunctions';
import useInfiniteScroll from '../../../../../../../../../hooks/useInfinityScroll';
import { ListLoader } from '../../../../../../../../../shared/ListLoader';
import useSelectedAnswer from '../../../../../hooks/useSelectedAnswer';
import { sendWhatsappMessage } from '../../../../../../../../../utils/sendWhatsappMessage';
import useConfirmModal from '../../../../../hooks/useConfirmModal';
import { useResponsive } from '../../../../../../../../../hooks/useResponsive';
import { Circle } from '../../../../../../../../../shared/Circle';

interface Props {
  answerList: AnswerQuotation[];
  handleRowClick: (answer: AnswerQuotation) => void;
  onScroll: () => void;
  isLoading: boolean;
  isEmpty?: boolean;
  notFound?: boolean;
}

export const DesktopList = ({
  answerList,
  handleRowClick,
  onScroll,
  isLoading,
  isEmpty = false,
  notFound = false,
}: Props) => {
  const confirmModal = useConfirmModal();
  const selectedAnswer = useSelectedAnswer();

  const listRef = useInfiniteScroll(onScroll);

  const message = useMemo(() => {
    if (isEmpty) return 'Sem Novas Cotações';
    if (notFound) return 'Nenhuma Cotação Encontrada';
    return null;
  }, [isEmpty, notFound]);

  const handleSendMessage = useCallback(
    (answer: AnswerQuotation) =>
      sendWhatsappMessage(
        `Olá *${
          answer?.quotation?.workshop?.fantasy_name ?? ''
        }* , gostaria de mais informações para envio da cotação *n° ${
          answer?.quotation?.QUOTATION ?? ''
        }* gerada pelo *Autocenter*`,
        answer?.quotation?.workshop?.whatsapp ?? '',
      ),
    [],
  );

  return (
    <Table.Root>
      <Table.Row className={styles['table-header']}>
        <Table.Header value="N° Cotação" align="left" />
        <Table.Header value="Origem" align="left" />
        <Table.Header value="Data de Criação" align="left" />
        <Table.Header value="Data da Aprovação" align="left" />
      </Table.Row>

      <div ref={listRef} className={styles['table-content']}>
        {message && <p>{message}</p>}
        {!message &&
          answerList.map(answerItem => (
            <ListItem
              key={answerItem.id_answer_quotation}
              answer={answerItem}
              isSelected={
                !!selectedAnswer.answer &&
                selectedAnswer.answer.id_answer_quotation ===
                  answerItem.id_answer_quotation
              }
              onRowClick={() => handleRowClick(answerItem)}
              onSendMessage={() => handleSendMessage(answerItem)}
              onConfirm={() => confirmModal.onOpen()}
            />
          ))}
        {!message && isLoading && <ListLoader />}
      </div>
    </Table.Root>
  );
};

interface ListItemProps {
  answer: AnswerQuotation;
  onSendMessage: () => void;
  onConfirm: () => void;
  onRowClick: (answer: AnswerQuotation) => void;
  isSelected: boolean;
}

const ListItem = ({
  answer,
  isSelected,
  onSendMessage,
  onRowClick,
  onConfirm,
}: ListItemProps) => {
  const quotationNumber = useMemo(() => {
    if (!answer?.quotation?.QUOTATION) return '---';
    return answer.quotation.QUOTATION;
  }, [answer?.quotation?.QUOTATION]);

  const createdAt = useMemo(() => {
    if (!answer?.quotation?.created_at) return '--/--/--';
    return formatDate(dateOffset(new Date(answer?.quotation?.created_at)));
  }, [answer?.quotation?.created_at]);

  const approvedAt = useMemo(() => {
    if (!answer?.quotation?.updated_at) return '--/--/--';
    return formatDate(dateOffset(new Date(answer?.quotation?.updated_at)));
  }, [answer?.quotation?.updated_at]);

  const workshop = useMemo(() => {
    if (!answer?.quotation?.workshop?.fantasy_name) return '---';
    return answer.quotation.workshop.fantasy_name;
  }, [answer?.quotation?.workshop?.fantasy_name]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onRowClick(answer);
      e.stopPropagation();
    },
    [answer, onRowClick],
  );

  const visualizedStatus = useMemo(() => {
    if (answer.visualized) return 'default';
    return 'notification';
  }, [answer.visualized]);

  const RowIcon = useMemo(
    () => (isSelected ? CaretUp : CaretDown),
    [isSelected],
  );

  const { sizeMobile } = useResponsive();

  return (
    <div>
      <Table.Row
        className={`${styles['table-row']} ${
          isSelected ? styles.selected : ''
        }`}
        onClick={handleClick}
      >
        <Table.Cell alignStart>
          <div className={styles['first-line']}>
            <RowIcon size={20} />
            {quotationNumber}
            <Circle variant={visualizedStatus} />
          </div>
        </Table.Cell>

        <Table.Cell alignStart>{workshop}</Table.Cell>
        <Table.Cell alignStart={!sizeMobile}>{createdAt}</Table.Cell>
        <Table.Cell alignStart>{approvedAt}</Table.Cell>
      </Table.Row>
      {isSelected && (
        <Table.Row className={styles['row-actions']}>
          <Button handleClick={onConfirm} variant="border">
            <Eye size={20} weight="bold" />
            Visualizar
          </Button>
          <Button handleClick={onSendMessage}>
            <WhatsappLogo size={20} weight="fill" />
            Whatsapp
          </Button>
        </Table.Row>
      )}
    </div>
  );
};
