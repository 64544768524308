import { Quotation } from '../../../../../../../../@types/interface';
import { useResponsive } from '../../../../../../../../hooks/useResponsive';
import { DesktopList } from './Desktop';
import { MobileList } from './Mobile';

interface Props {
  quotationList: Quotation[];
  handleRowClick: (quotation: Quotation) => void;
  onOpenRedoModal: () => void;
  onScroll: () => void;
  isLoading: boolean;
  isEmpty?: boolean;
  notFound?: boolean;
}

export const QuotationList = (quotationListProps: Props) => {
  // const { sizeMobile } = useResponsive();

  // if (sizeMobile) return <MobileList {...quotationListProps} />;

  return <DesktopList {...quotationListProps} />;
};
