import ReactDOMServer from 'react-dom/server';
import { InfoRow } from '../components/html/info-row';
import { Section } from '../components/html/section';
import { ServiceTable } from '../components/html/service-table';
import { Container } from '../components/html/container';
import { BudgetResponse } from './interfaces';
import { maskCpfCnpj, maskPhone } from '../../../services/helpers/mask';
import { QRCode } from '../../qrcode';
import { formatDate, formatTime } from '../../dateFunctions';

export const budgetPdf = (budget: BudgetResponse) => {
  const generateClientSection = () => {
    return {
      title: 'Cliente',
      content: InfoRow({
        content: [
          {
            name: 'Nome',
            value: budget?.name ?? '-',
          },
          {
            name: 'Telefone',
            value: maskPhone(budget?.phone ?? '-'),
          },
          {
            name: 'CPF/CNPJ',
            value: maskCpfCnpj(budget?.document ?? '-'),
          },
        ],
      }),
    };
  };

  const generateVehicleSection = () => {
    const items = [
      {
        name: 'Modelo',
        value: budget?.vehicle ?? '-',
      },
      {
        name: 'Placa',
        value: budget?.plate ?? '-',
      },
      {
        name: 'km',
        value: budget?.status ?? '-',
      },
    ];
    return {
      title: 'Veículo',
      content: InfoRow({
        content: items,
      }),
    };
  };

  const generateServiceSection = () => {
    let services: { name: string; quantity: number; unit: number }[] = [];
    let discount = 0;
    if (budget) {
      discount = budget.discount;
      services = budget.child_services.map(service => ({
        name: service.name,
        quantity: parseFloat(service.quantity),
        unit: service.total / parseFloat(service.quantity),
      }));
    }

    return {
      title: 'Serviço',
      content: `
        ${InfoRow({
          content: [
            {
              name: 'Defeitos',
              value: budget?.defect ?? '-',
            },
          ],
        })}
        ${ServiceTable({
          discount,
          items: services,
        })}
      `,
    };
  };

  const generateCommentSection = () => {
    const items = [
      {
        name: 'Observações',
        value: budget?.comment ?? '-',
        fullLine: true,
      },
    ];
    return {
      title: 'Observações',
      content: `
        ${InfoRow({
          content: items,
        })}
      `,
    };
  };

  const generateQRCode = () => {
    if (
      budget &&
      budget.checklist &&
      budget.checklist.banners &&
      budget.checklist.banners.length > 0
    ) {
      return ReactDOMServer.renderToString(
        QRCode(
          `https://oficinas.autocenterapp.com/checklist-report?checklistId=${budget.checklist_id}`,
        ),
      );
    }
    return '';
  };

  return `${Container({
    workshopName: `${budget?.workshop?.fantasy_name ?? ''}`,
    documentTitle: `Orçamento - ${budget?.BUDGET ?? ''}`,
    content: `
    ${InfoRow({
      content: [
        {
          name: 'Data de Emissão',
          value: `${
            budget && budget.created_at
              ? `${formatDate(new Date(budget.created_at))} - ${formatTime(
                  new Date(budget.created_at),
                )}`
              : '-'
          }`,
        },
        {
          name: 'Validade',
          value: budget?.validity ?? '-',
        },
      ],
    })}
      ${Section(generateClientSection())}
      ${Section(generateVehicleSection())}
      ${Section(generateServiceSection())}
      ${Section(generateCommentSection())}
      ${generateQRCode()}
    `,
  })}`;
};
