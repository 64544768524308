import React from 'react';

import styles from './modal.module.css';

type ModalTitleProps = React.ComponentPropsWithoutRef<'h2'> & {
  titleClassName?: string;
};

export const ModalTitle = React.forwardRef<HTMLHeadingElement, ModalTitleProps>(
  ({ children, titleClassName, ...props }, forwardRef) => {
    return (
      <h2
        {...props}
        ref={forwardRef}
        className={`${styles.title} ${titleClassName}`}
      >
        {children}
      </h2>
    );
  },
);
