import React from 'react';
import { Modal } from '../../../../../../shared/Modal';
import { Banner } from '../../../../../../@types/interface';
import styles from './view-images-modal.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  banners: Banner[];
}

export const ViewImagesModal = ({ isOpen, onClose, banners }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Visualizar Imagens">
      {banners.length === 0 && <h1>Nenhuma Imagem Encontrada</h1>}
      <div className={styles['images-wrapper']}>
        {banners.map(banner => (
          <img
            key={banner.id_banner}
            src={banner.img_url}
            alt="Imagens da cotação"
          />
        ))}
      </div>
    </Modal>
  );
};
