import { useCallback, useEffect, useMemo, useState } from 'react';
import { Quotation } from '../../../../../../@types/interface';
import { Modal } from '../../../../../../shared/Modal';
import { BoxInfo } from '../BoxInfo';
import { QuotationItems } from '../QuotationItems';
import styles from './view-quotation-modal.module.css';
import { AutoshopsList } from '../AutoshopsList';
import { useFetchQuotation } from '../../hooks/useFetchQuotation';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  quotation: Quotation | null;
  isOnCreationView?: boolean;
  quotationId?: string;
}

export const ViewQuotationModal = ({
  isOpen,
  onClose,
  quotation,
  isOnCreationView = false,
  quotationId = '',
}: Props) => {
  const { fetchQuotation, isLoading } = useFetchQuotation();

  const [fetchedQuotation, setFetchedQuotation] = useState(quotation);

  const getQuotation = useCallback(async () => {
    if (!quotation?.id_quotation && quotationId.length === 0) return;
    const res = await fetchQuotation(
      quotationId.length > 0 ? quotationId : quotation?.id_quotation ?? '',
    );
    if (res) {
      setFetchedQuotation(res);
    }
  }, [fetchQuotation, quotation?.id_quotation, quotationId]);

  useEffect(() => {
    if (isOpen) {
      getQuotation();
    }
  }, [quotation, isOpen, getQuotation]);

  const ModalFooter = (
    <div className={styles.actions}>
      <button type="button" onClick={onClose}>
        Voltar
      </button>
    </div>
  );

  const modalTitle = useMemo(() => {
    if (isLoading) return 'buscando cotação';
    const quotationNumber = fetchedQuotation?.QUOTATION ?? '';

    return `Visualizar cotação - Nº ${quotationNumber}`;
  }, [fetchedQuotation?.QUOTATION, isLoading]);

  return (
    <Modal
      title={modalTitle}
      isOpen={isOpen}
      onClose={onClose}
      size="large"
      footer={ModalFooter}
      disableScroll
      isLoading={isLoading}
    >
      <div className={styles.container}>
        <BoxInfo quotation={fetchedQuotation} />
        <QuotationItems quotation={fetchedQuotation} />
        {isOnCreationView && fetchedQuotation && (
          <AutoshopsList quotation={fetchedQuotation} />
        )}
      </div>
    </Modal>
  );
};
