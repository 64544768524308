import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { BiRefresh } from 'react-icons/bi';
import { Minus, Plus } from 'phosphor-react';
import {
  ModalContent,
  ModalTitle,
} from '../../pages/common/Finance/components/Modal';
import { Button } from '../Button';
import styles from './image-modal.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  imgUrl: string;
}

export const ImageModal = ({ isOpen, imgUrl, onClose }: Props) => {
  return (
    <ModalContent open={isOpen} onClose={onClose} full borderTop>
      <ModalTitle>Visualizar Imagem</ModalTitle>
      <div className={styles.container}>
        <TransformWrapper initialScale={1}>
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <div className={styles.controls}>
                <Button
                  icon={<Plus size={32} />}
                  handleClick={() => zoomIn()}
                  variant="icon"
                />
                <Button
                  icon={<Minus size={32} />}
                  handleClick={() => zoomOut()}
                  variant="icon"
                />
                <Button
                  icon={<BiRefresh size={32} />}
                  handleClick={() => resetTransform()}
                  variant="icon"
                />
              </div>
              <TransformComponent>
                <img
                  src={imgUrl}
                  alt="Visualização da imagem"
                  className={styles.image}
                />
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </div>
    </ModalContent>
  );
};
