import { useEffect, useRef } from 'react';
import { useNotification } from '../contexts/notificationContext';

interface Props {
  audioPath: string;
  volume?: number;
  minInterval?: number;
}

export const usePlayAudio = ({
  audioPath,
  volume = 1.0,
  minInterval = 1.5 * 1000,
}: Props) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const { lastNotificationPlayedTime, setNotificationState } =
    useNotification();

  useEffect(() => {
    const audio = new Audio(audioPath);
    audio.volume = volume;
    audioRef.current = audio;

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, [audioPath, volume]);

  const onPlay = () => {
    const now = Date.now();
    const isAvailableToPlay = now - lastNotificationPlayedTime >= minInterval;
    if (audioRef.current && isAvailableToPlay) {
      audioRef.current
        .play()
        .then(() => {
          setNotificationState(previous => ({
            ...previous,
            lastNotificationPlayedTime: now,
          }));
        })
        .catch(error => {
          console.error('Failed to play audio:', error);
        });
    }
  };

  const onPause = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const onStop = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  return {
    player: {
      onPlay,
      onPause,
      onStop,
    },
  };
};
