/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-useless-fragment */
import { ReactNode, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styles from './modal.module.css';
import { setOpen } from '../../../../../features/modalLanginPage/openModal';
import { useAppSelector } from '../../../../../hooks/useAppSelector';

interface Props {
  children: ReactNode;
}

export const Modal = ({ children }: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { show } = useAppSelector(state => state.openModalRegister);

  useEffect(() => {
    if (show && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [show]);

  const handleClose = (e: any) => {
    if (e.target.id === 'click') {
      dispatch(setOpen({ show: false }));
    }
  };

  if (show) {
    return (
      <div className={styles.overlay} onClick={e => handleClose(e)} id="click">
        <div className={styles.content} ref={modalRef}>
          <div className={styles.body}>{children}</div>
        </div>
      </div>
    );
  }
  return <></>;
};
