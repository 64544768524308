import React, { ForwardRefExoticComponent, RefAttributes } from 'react';

import { IconProps } from '@phosphor-icons/react';
import styles from './input.module.css';

type VariantProps = 'default' | 'secondary' | 'danger' | 'success';
type SizeProps = 'sm' | 'md' | 'lg';

type InputProps = React.ComponentPropsWithoutRef<'div'> & {
  inputClassname?: string;
  variant?: VariantProps;
  Icon?: ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ Icon, inputClassname, variant = 'default', ...props }, ref) => {
    return (
      <div className={styles['input-box']}>
        <input
          {...props}
          ref={ref}
          className={`${styles['default-input']} ${styles[variant]} ${inputClassname}`}
        />
        {Icon && <Icon />}
      </div>
    );
  },
);
export type InputLabelProps = React.ComponentPropsWithoutRef<'span'> & {
  labelClassName?: string;
  labelSize?: SizeProps;
};

export const InputLabel = React.forwardRef<HTMLSpanElement, InputLabelProps>(
  ({ children, labelSize = 'md', labelClassName }, ref) => {
    return (
      <span
        className={`${styles['default-label']} ${labelClassName} ${styles[labelSize]}`}
        ref={ref}
      >
        {children}
      </span>
    );
  },
);

export type InputHintProps = React.ComponentPropsWithoutRef<'span'> & {
  hintClassName?: string;
  variant?: VariantProps;
};

export const InputHint = React.forwardRef<HTMLSpanElement, InputHintProps>(
  ({ children, hintClassName, variant = 'default' }, ref) => {
    return (
      <span
        className={`${styles['default-hint']} ${styles[variant]} ${hintClassName}`}
        ref={ref}
      >
        {children}
      </span>
    );
  },
);

type InputRootProps = React.ComponentPropsWithoutRef<'div'> & {
  RootClassname?: string;
};

export const InputRoot = React.forwardRef<HTMLDivElement, InputRootProps>(
  ({ RootClassname, children, ...props }, ref) => {
    return (
      <div
        className={`${styles['default-root']} ${RootClassname}`}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    );
  },
);

type InputComponentProps = React.ComponentPropsWithoutRef<'input'> & {
  label?: string;
  hint?: string;
  RootClassname?: string;
  hintClassname?: string;
  labelClassname?: string;
  labelSize?: SizeProps;
  inputClassname?: string;
  variant?: VariantProps;
  icon?: ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>;
};

export const InputComponent = React.forwardRef<
  HTMLInputElement,
  InputComponentProps
>(
  (
    {
      RootClassname,
      label,
      hint,
      hintClassname,
      labelClassname,
      labelSize = 'md',
      inputClassname,
      icon,
      variant = 'default',
      ...props
    },
    ref,
  ) => {
    return (
      <InputRoot RootClassname={RootClassname}>
        <InputLabel labelClassName={labelClassname} labelSize={labelSize}>
          {label}
        </InputLabel>
        <Input
          inputClassname={inputClassname}
          variant={variant}
          Icon={icon}
          ref={ref}
          {...props}
        />
        <InputHint hintClassName={hintClassname} variant={variant}>
          {hint}
        </InputHint>
      </InputRoot>
    );
  },
);
