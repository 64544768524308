import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { handleHtmlToPdf } from '../handleHtmlToPdf';
import { budgetPdf } from './budgetPdf';
import { Container } from '../components/jsx/Container';
import { Section } from '../components/jsx/Section';
import { InfoRow } from '../components/jsx/InfoRow';
import { ServiceTable } from '../components/jsx/ServiceTable';
import { BudgetResponse } from './interfaces';
import { getBudgetDataController } from './controller';
import { maskCpfCnpj, maskPhone } from '../../../services/helpers/mask';
import { formatDate, formatTime } from '../../dateFunctions';
import { isWebView } from '../../detectUserAgent';
import { QRCode } from '../../qrcode/index';

export const BudgetReport = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const budgetId = queryParams.get('budgetId');

  const [budget, setBudget] = useState<BudgetResponse | null>(null);

  const fetchBudget = useCallback(async () => {
    const res = await getBudgetDataController(budgetId || '');

    if (res) {
      setBudget(res);
    }
  }, [budgetId]);

  useEffect(() => {
    fetchBudget();
  }, [budgetId, fetchBudget]);

  const handleDownloadPdf = () => {
    if (budget) {
      const html = budgetPdf({
        ...budget,
      });
      const pdfProps = {
        html,
        name: 'pdf',
      };
      if (isWebView === '1') {
        window.ReactNativeWebView.postMessage(JSON.stringify(pdfProps));
      } else {
        handleHtmlToPdf(pdfProps.html, pdfProps.name);
      }
    }
  };

  const generateClientSection = () => {
    const items = [
      {
        name: 'Nome',
        value: budget?.name ?? '-',
      },
      {
        name: 'Telefone',
        value: maskPhone(budget?.phone ?? '-'),
      },
      {
        name: 'CPF/CNPJ',
        value: maskCpfCnpj(budget?.document ?? '-'),
      },
    ];
    return (
      <Section title="Cliente">
        <InfoRow items={items} />
      </Section>
    );
  };
  const generateVehicleSection = () => {
    const items = [
      {
        name: 'Modelo',
        value: budget?.vehicle ?? '-',
      },
      {
        name: 'Placa',
        value: budget?.plate ?? '-',
      },
      {
        name: 'km',
        value: budget?.status ?? '-',
      },
    ];
    return (
      <Section title="Veículo">
        <InfoRow items={items} />
      </Section>
    );
  };
  const generateServiceSection = () => {
    let services: { name: string; quantity: number; unit: number }[] = [];
    let discount = 0;
    if (budget) {
      discount = budget.discount;
      services = budget.child_services.map(service => ({
        name: service.name,
        quantity: parseFloat(service.quantity),
        unit: service.total / parseFloat(service.quantity),
      }));
    }
    return (
      <Section title="Serviços">
        <InfoRow
          items={[
            {
              name: 'Defeitos',
              value: budget?.defect ?? '-',
            },
          ]}
        />
        <ServiceTable discount={discount} services={services} />
      </Section>
    );
  };

  const generateCommentSection = () => {
    const items = [
      {
        name: 'Observações',
        value: budget?.comment ?? '-',
        fullLine: true,
      },
    ];
    return (
      <Section title="Cliente">
        <InfoRow items={items} />
      </Section>
    );
  };

  const generateQRCode = () => {
    if (
      budget &&
      budget.checklist &&
      budget.checklist.banners &&
      budget.checklist.banners.length > 0
    ) {
      return QRCode(
        `https://oficinas.autocenterapp.com/checklist-report?checklistId=${budget.checklist_id}`,
      );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  };

  return (
    <Container
      handleDownloadPdf={handleDownloadPdf}
      workshopName={
        (budget && budget.workshop.fantasy_name) ?? 'Oficina não encontrada'
      }
      documentName={`Orçamento - ${budget?.BUDGET ?? ''}`}
    >
      <InfoRow
        items={[
          {
            name: 'Data de Emissão',
            value: `${
              budget && budget.created_at
                ? `${formatDate(new Date(budget.created_at))} - ${formatTime(
                    new Date(budget.created_at),
                  )}`
                : '-'
            }`,
          },
          {
            name: 'Validade',
            value: budget?.validity ?? '-',
          },
        ]}
      />
      {generateClientSection()}
      {generateVehicleSection()}
      {generateServiceSection()}
      {generateCommentSection()}
      {generateQRCode()}
    </Container>
  );
};
