import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { AnswerQuotation } from '../../../../../../../../@types/interface';
import { useResponsive } from '../../../../../../../../hooks/useResponsive';
import { Modal } from '../../../../../../../../shared/Modal';
import { Input } from '../../../../../../../../shared/Input';
import { Textarea } from '../../../../../../../../shared/Textarea';
import { ProductTable } from '../../../../../../../../shared/ProductTable';
import { ItemQuotationList } from '../../../../../../../../shared/ItemQuotationList';
import styles from './view-quotation.module.css';
import { ListTable } from '../../../../../../../../shared/ListTable';
import { getQuotationById } from '../../../../model';
import { getAnswerQuotationById } from '../../../../../autoshop/model';
import { getAnswerQuotationByIdController } from '../../../../../autoshop/controller';
import { sendWhatsappMessage } from '../../../../../../../../utils/sendWhatsappMessage';
import { maskPhone } from '../../../../../../../../services/helpers/mask';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  answer: AnswerQuotation | null;
}

interface Quote {
  description: string;
  reference: string;
  quantity: string;
  value: string;
  availableQuantity: string;
}

export interface FormValues {
  plate?: string;
  vehicle?: string;
  chassi?: string;
  year?: string;
  model?: string;
  quotes: Quote[];
  observation?: string;
  paymentMethod: string;
}

export const ViewQuotationModal = ({ isOpen, onClose, answer }: Props) => {
  const { sizeMobile } = useResponsive();

  const [searchedAnswerQuotation, setSearchedAnswerQuotation] =
    useState<AnswerQuotation | null>(null);

  const fetchQuotationById = useCallback(async () => {
    const res = await getAnswerQuotationByIdController(
      answer?.id_answer_quotation ?? '',
    );

    if (res && res.quotation) {
      setSearchedAnswerQuotation(res);
    }
  }, [answer?.id_answer_quotation]);

  useEffect(() => {
    fetchQuotationById();
  }, [fetchQuotationById]);

  const handleWhatsapp = () => {
    sendWhatsappMessage(
      `Olá *${searchedAnswerQuotation?.workshop?.fantasy_name}*, gostaria de falar sobre a cotação ${searchedAnswerQuotation?.quotation?.QUOTATION} gerada pelo *Autocenter*`,
      searchedAnswerQuotation?.workshop?.whatsapp ?? '',
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Visualizar Cotação - N° ${
        searchedAnswerQuotation?.quotation?.QUOTATION || ''
      }`}
      size={sizeMobile ? 'full' : 'large'}
      subtitle={
        <h3
          style={{
            color: 'var(--error)',
            textTransform: 'uppercase',
          }}
        >
          {answer?.rejected_by_reciever ? 'rejeitada' : ''}
        </h3>
      }
    >
      <div className={styles['vehicle-info']}>
        <div className={styles.row}>
          <Input
            label="Placa:"
            value={
              searchedAnswerQuotation?.quotation?.vehicle?.license_plate ?? ''
            }
            disabled
            style={{ fontWeight: '600', color: '#000000' }}
            name="plate"
          />

          <Input
            name="vehicle"
            label="Veículo:"
            value={searchedAnswerQuotation?.quotation?.vehicle?.name ?? ''}
            disabled
            style={{ fontWeight: '600', color: '#000000' }}
          />
          <Input
            name="model"
            label="Modelo:"
            value={searchedAnswerQuotation?.quotation?.vehicle?.model ?? ''}
            disabled
            style={{ fontWeight: '600', color: '#000000' }}
          />
        </div>
        <div className={styles.row}>
          <Input
            name="year"
            label="Ano:"
            value={searchedAnswerQuotation?.quotation?.vehicle?.year ?? ''}
            disabled
            style={{ fontWeight: '600', color: '#000000' }}
          />

          <Input
            name="chassi"
            label="Chassi:"
            value={searchedAnswerQuotation?.quotation?.vehicle?.chassi ?? ''}
            disabled
            style={{ fontWeight: '600', color: '#000000' }}
          />
        </div>
        <Input
          name="paymentMethod"
          label="Forma de Pagamento:"
          value={
            searchedAnswerQuotation?.quotation?.type_payment ?? 'Não Informado'
          }
          disabled
          style={{ fontWeight: '600', color: '#000000' }}
        />

        <div className={styles.observation}>
          <Textarea
            name="observation"
            value={searchedAnswerQuotation?.comment ?? ''}
            label="Observações:"
            disabled
            style={{
              height: '5rem',
              fontWeight: '500',
              color: '#000000',
            }}
          />
        </div>
      </div>
      <ProductTable.Root
        heading={
          <div>
            <div>
              <h3 className={styles.label}>
                Fornecedor:{' '}
                {searchedAnswerQuotation?.workshop?.fantasy_name ?? ''}
              </h3>
              {searchedAnswerQuotation?.agent && (
                <sub>
                  <b>
                    Vendedor: {searchedAnswerQuotation?.agent?.user?.name ?? ''}
                  </b>
                </sub>
              )}
            </div>
            <div>
              <ListTable.WhatsappButton onClick={handleWhatsapp} />
            </div>
          </div>
        }
      >
        <ul className={styles['table-header']}>
          <li>Item</li>
          <li>Descrição</li>
          <li>Qtd. Solicitada</li>
        </ul>
        <div className={styles.answer}>
          {searchedAnswerQuotation &&
            searchedAnswerQuotation.quotation &&
            searchedAnswerQuotation.quotation.items_quotation.map(
              (quotationItem, index) => {
                const correspondingAnswers =
                  searchedAnswerQuotation.items_answer_quotation.filter(
                    answerItem => {
                      return (
                        answerItem.item_quotation_id ===
                        quotationItem.id_item_quotation
                      );
                    },
                  );

                return (
                  <ItemQuotationList
                    index={`${index + 1}`}
                    name={quotationItem?.description ?? '----'}
                    quantity={quotationItem?.quantity ?? '----'}
                    responsive={sizeMobile ? 'mobile' : 'web'}
                    items={correspondingAnswers.map(item => ({
                      brand: item.brand ?? '',
                      reference: item.reference ?? '',
                      unitValue: item.total ? `${item.total}` : '----',
                      quantity: item.quantity_asked
                        ? `${item.quantity_asked}`
                        : '----',
                    }))}
                    type="itemView"
                    itemBrandType="finished"
                    notOffer={correspondingAnswers.length === 0}
                    key={quotationItem.id_item_quotation}
                  />
                );
              },
            )}
        </div>
        <div className={styles.observations}>
          <Textarea
            name="comment"
            handleChange={() => console.log()}
            label="Comentário do Fornecedor:"
            value={searchedAnswerQuotation?.comment ?? ''}
            disabled
            style={{
              height: '5rem',
              fontWeight: '500',
              color: '#000000',
            }}
          />
          <Textarea
            name="rebuttal"
            handleChange={() => console.log()}
            label="Resposta da Oficina:"
            value={searchedAnswerQuotation?.rebuttal ?? ''}
            disabled
            style={{
              height: '5rem',
              fontWeight: '500',
              color: '#000000',
            }}
          />
        </div>
      </ProductTable.Root>
    </Modal>
  );
};
