import { useState, useCallback } from 'react';
import { getQuotationByIdController } from '../controller';

export const useFetchQuotation = () => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchQuotation = useCallback(async (id: string) => {
    setIsLoading(true);

    try {
      const res = await getQuotationByIdController(id);
      return res;
    } catch (err) {
      console.error(err);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { fetchQuotation, isLoading };
};
