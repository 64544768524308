import React, { ComponentProps, CSSProperties } from 'react';
import styles from './row-item.module.css';

interface Props extends ComponentProps<'div'> {
  children: React.ReactNode;
  style?: CSSProperties;
}

export const RowItem = ({ children, style, ...props }: Props) => {
  return (
    <div
      className={`${styles.item} ${props.className}`}
      style={style}
      {...props}
    >
      {children}
    </div>
  );
};
