import { Eye } from '@phosphor-icons/react';
import { Button } from '../../../Button';
import { COLORS } from '../../../../styles/colors';

interface Props {
  onClick: () => void;
  text?: string;
  icon?: React.ReactNode;
}

export const ViewButton = ({
  onClick,
  text = 'Visualizar',
  icon = <Eye size={20} color={COLORS.WHITE} />,
}: Props) => {
  return (
    <Button handleClick={onClick}>
      <Eye size={20} color={COLORS.WHITE} weight="bold" />
      <span>{text}</span>
    </Button>
  );
};
