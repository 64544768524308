import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface showRegister {
  route: string;
}

const isColor = createSlice({
  name: 'color',
  initialState: { route: '/landing-page' },
  reducers: {
    setColor: (state, action: PayloadAction<showRegister>) => {
      return action.payload;
    },
  },
});

export const { setColor } = isColor.actions;

export const colorActiveHeaderLanding = isColor.reducer;
