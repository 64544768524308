import { Loading } from '../Loading';
import styles from './list-loader.module.css';

export const ListLoader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <Loading />
        <Loading />
        <Loading />
        <Loading />
        <Loading />
      </div>
    </div>
  );
};
