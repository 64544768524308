interface Props {
  content: string;
  cpf: string;
  name: string;
}

export const Container = ({ content, cpf, name }: Props) => {
  return `
    <html>
    <head>
      <title>Autocenter</title>
      <style>
        body {
          font-family: Bebas Neue, Arial;
        }
        @page {
          size: A4;
          margin: 0;
        }

        @media print {
          body {
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
          }
        }

        .WrapperContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding-top: 10rem;
          overflow: hidden;
        }

        .container {
          width: 80%;
          display: flex;
          flex-direction: column;
          border: 2px solid;
          border-radius: 2%;
          padding: 0 0.5rem 4rem 0.5rem;
        }

        .header {
          display: flex;
          align-items: flex-start;
          color: #007a37;
          width: 100%;
          justify-content: space-between;
          padding: 1rem;
          position: relative;
          height: 170px;
          z-index: 1;
        }

        .header h1 {
          align-self: flex-start;
          text-align: center;
          flex: 1;
          font-family: Bebas Neue, Arial;
          font-weight: 600;
          font-size: 2.5rem;
          z-index: 2;
          margin-top: 1.4rem;
        }

        .header .logo{
          margin-top: 1rem;
          z-index: 2;
        }
        .header .pattern {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        .content {
          display: flex;
          flex-direction: column;
          gap: 2rem;

        }

        .content .input_input-control {
          font-family: 'Inter', sans-serif;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          width: 100%;
          z-index: 1;
        }

        .content .input_input-control label {
          font-weight: 600;
          font-size: 2rem;
          font-family: 'Inter', sans-serif;
        }

        .content .input_input-control input {
          border: 2px solid;
          border-radius: 8px;
          background-color: #f5f7fa;
          height: 3rem;
          padding: 1rem 0.2rem 1rem 1.5rem;
          width: 100%;
          font-size: 1.8rem;
        }

        .content .qr_code {
          display: flex;
          flex: 1;
          justify-content: center;
        }

        h1,
        h2,
        h3 {
          padding: 0.5rem 0 0.5rem 0.5rem;
        }

      </style>
    </head>
    <body>
      <div class="WrapperContainer">
        <div class="container" id="container">
          <div class="header">
            <svg class="logo" width="74" height="75" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M54.3545 0.399414H9.64549C4.31844 0.399414 0 5.0125 0 10.703V54.0958C0 59.7863 4.31844 64.3994 9.64549 64.3994H54.3545C59.6816 64.3994 64 59.7863 64 54.0958V10.703C64 5.0125 59.6816 0.399414 54.3545 0.399414Z" fill="#010101"/>
              <path d="M31.4391 12.4683C31.3484 12.4683 31.2619 12.5042 31.198 12.5688L25.0953 18.7128L21.524 22.3233C21.3905 22.4579 21.1738 22.4579 21.0403 22.3233L15.6308 16.837C15.4999 16.7042 15.2873 16.7017 15.1532 16.831L11.6137 20.2482C11.4777 20.3799 11.4747 20.5966 11.6075 20.7313L17.3027 26.5063L21.0421 30.0892C21.1756 30.2171 21.3868 30.2146 21.5173 30.0842L25.0953 26.5063L28.9919 22.6095L32.8886 18.7128L38.5527 13.0488C38.7666 12.8349 38.6148 12.4689 38.3127 12.4689H31.44L31.4394 12.4683H31.4391Z" fill="#FEFEFE"/>
              <path d="M27.7361 39.4401C23.909 39.2464 20.7653 42.39 20.9597 46.2166C21.126 49.4936 23.7884 52.156 27.0647 52.3222C30.892 52.516 34.0356 49.3724 33.8412 45.5459C33.675 42.2688 31.0125 39.6064 27.7361 39.4408V39.4401ZM27.7245 49.8646C25.262 50.059 23.223 48.0194 23.4168 45.5568C23.5696 43.6195 25.1391 42.0501 27.0758 41.8978C29.5383 41.7034 31.578 43.743 31.3836 46.2056C31.2307 48.1429 29.6612 49.7123 27.7245 49.8646Z" fill="#FEFEFE"/>
              <path d="M41.9759 39.894C41.9759 39.763 41.8997 39.6431 41.781 39.587C39.74 38.6201 40.3406 39.2415 40.3406 37.4559C40.3406 35.3961 38.6019 34.615 37.2732 34.0448C37.2428 34.0319 37.2117 34.0234 37.1794 34.0197L22.9289 30.7305C22.8217 30.7159 22.7144 30.7524 22.6388 30.8298L21.4623 32.038C21.3302 32.1738 21.1115 32.175 20.978 32.0404L19.6829 30.7366L9.633 20.7153C9.56905 20.6465 9.47891 20.6069 9.38452 20.6069L7.98449 20.661C7.79679 20.661 7.64453 20.8133 7.64453 21.001V22.2273C7.64453 22.415 7.79679 22.5673 7.98449 22.5673H8.10459C8.20142 22.5673 8.294 22.6087 8.35855 22.6812L16.1633 31.4657C16.3583 31.6851 16.2024 32.0311 15.9094 32.0311H7.98449C7.79679 32.0311 7.64453 32.1834 7.64453 32.371V46.8239C7.64453 47.0108 7.79557 47.1625 7.98205 47.1637L19.7737 47.2374C19.9791 47.2386 20.1381 47.059 20.1118 46.8548C20.0734 46.5538 20.0528 46.248 20.0528 45.9368C20.0528 41.8994 23.3255 38.626 27.3628 38.626C31.4003 38.626 34.6735 41.8988 34.6735 45.9368C34.6735 46.2784 34.6492 46.6149 34.6035 46.9437C34.5749 47.1479 34.7309 47.3306 34.9368 47.3325L40.0488 47.3648C41.1028 47.3989 41.9759 46.554 41.9759 45.5V39.8939V39.894ZM38.7135 39.6077C37.0582 39.3182 35.3944 38.8827 35.2574 37.7574C35.1208 36.6322 34.8139 35.2724 35.9391 35.136C37.5695 34.9379 38.9634 35.6763 38.9817 37.1707C39.006 39.1849 39.8291 39.8027 38.7136 39.6077H38.7135Z" fill="#FEFEFE"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M53.0091 47.0109C53.4232 48.7487 56.8959 48.7632 56.2841 45.7504L54.0795 34.8973C53.9591 34.3039 53.7488 33.7889 53.8744 33.3876C54.2583 32.1614 53.8198 31.4033 53.6455 30.6026C53.3041 29.0319 51.4595 23.9119 50.8415 22.0796C50.3416 20.5978 49.6486 19.4169 47.5928 19.6744C46.457 19.8164 45.2437 21.2001 44.4373 22.1016C43.4628 23.1911 42.5415 23.9258 41.6496 24.9919C40.4124 24.9544 36.6614 24.3271 35.8041 24.7848C34.8044 25.3187 33.8772 27.746 37.1151 28.012C42.8299 28.4818 42.4703 29.0565 44.4536 26.844C44.9198 26.3242 45.6274 25.8389 46.018 25.2892C47.7077 28.6573 47.6412 32.8519 50.14 34.094C50.1735 35.7814 52.5682 46.3062 53.0089 47.0109H53.0091Z" fill="#FEFEFE"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M43.0726 12.6198C38.4517 13.9275 40.1747 21.0893 45.1284 19.7949C46.7714 19.3655 48.2877 17.5244 47.7098 15.2658C47.2526 13.4788 45.3477 11.976 43.0726 12.6198Z" fill="#FEFEFE"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M43.8277 46.546C44.1734 47.5504 44.9948 48.2217 46.1151 47.9278C46.3956 47.8543 46.6645 47.7093 46.856 47.5467C47.0503 47.3816 47.0036 47.3977 47.1509 47.2216C47.7206 46.574 47.9943 41.596 48.1257 40.3828C48.3769 38.0637 49.1621 38.2483 49.4585 37.361C49.54 37.1172 49.5846 36.7924 49.5709 36.3123C49.5534 35.6944 49.5411 35.0768 49.5319 34.4586C48.718 34.0596 48.5018 33.5906 47.9788 32.8752L44.8344 37.5577C44.5641 38.0142 43.5617 45.7242 43.8277 46.546Z" fill="#FEFEFE"/>
            </svg>

            <svg class="pattern" width="380" height="149" viewBox="0 0 380 169" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M-80.9766 110.951C-80.9766 110.223 -80.6878 109.529 -80.1693 109.015L-30.8572 60.0354L-1.88008 31.3728C-0.79924 30.3025 -0.79924 28.561 -1.88008 27.4907L-45.9119 -15.9244C-46.9777 -16.9765 -46.9973 -18.6816 -45.9603 -19.7579L-18.5326 -48.1666C-17.476 -49.2565 -15.7345 -49.2823 -14.6552 -48.215L31.6956 -2.50673L60.4505 27.5059C61.4769 28.5762 61.4573 30.2722 60.4112 31.3198L31.694 60.0369L0.419159 91.3119L-30.8557 122.587L-76.3161 168.047C-78.0318 169.763 -80.972 168.546 -80.972 166.12L-80.972 110.959L-80.975 110.953L-80.9766 110.951Z" fill="#ABBED1" fill-opacity="0.25"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M-0.474609 110.951C-0.474609 110.223 -0.185886 109.529 0.332616 109.015L49.6462 60.0354L78.6234 31.3728C79.7042 30.3025 79.7042 28.561 78.6234 27.4907L34.5915 -15.9244C33.5258 -16.9765 33.5062 -18.6816 34.5432 -19.7579L61.9708 -48.1666C63.0275 -49.2565 64.7689 -49.2823 65.8483 -48.215L112.199 -2.50673L140.954 27.5059C141.98 28.5762 141.961 30.2722 140.915 31.3198L112.198 60.0369L80.9226 91.3119L49.6477 122.587L4.18737 168.047C2.47162 169.763 -0.468575 168.546 -0.468575 166.12L-0.468575 110.959L-0.473096 110.953L-0.474609 110.951Z" fill="#ABBED1" fill-opacity="0.25"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M79.5488 110.951C79.5488 110.223 79.8376 109.529 80.3561 109.015L129.67 60.0354L158.647 31.3728C159.728 30.3025 159.728 28.561 158.647 27.4907L114.615 -15.9244C113.548 -16.9765 113.53 -18.6816 114.567 -19.7579L141.994 -48.1666C143.051 -49.2565 144.791 -49.2823 145.872 -48.215L192.222 -2.50673L220.979 27.5059C222.005 28.5762 221.986 30.2722 220.94 31.3198L192.222 60.0369L160.948 91.3119L129.673 122.587L84.2123 168.047C82.4966 169.763 79.5564 168.546 79.5564 166.12L79.5564 110.959L79.5503 110.953L79.5488 110.951Z" fill="#ABBED1" fill-opacity="0.25"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M157.072 110.951C157.072 110.223 157.361 109.529 157.879 109.015L207.193 60.0354L236.17 31.3728C237.251 30.3025 237.251 28.561 236.17 27.4907L192.138 -15.9244C191.071 -16.9765 191.053 -18.6816 192.09 -19.7579L219.518 -48.1666C220.574 -49.2565 222.314 -49.2823 223.395 -48.215L269.746 -2.50673L298.502 27.5059C299.529 28.5762 299.509 30.2722 298.463 31.3198L269.746 60.0369L238.471 91.3119L207.196 122.587L161.736 168.047C160.02 169.763 157.08 168.546 157.08 166.12L157.08 110.959L157.074 110.953L157.072 110.951Z" fill="#ABBED1" fill-opacity="0.25"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M237.098 110.951C237.098 110.223 237.386 109.529 237.905 109.015L287.219 60.0354L316.196 31.3728C317.276 30.3025 317.276 28.561 316.196 27.4907L272.164 -15.9244C271.098 -16.9765 271.078 -18.6816 272.115 -19.7579L299.543 -48.1666C300.6 -49.2565 302.341 -49.2823 303.421 -48.215L349.771 -2.50673L378.526 27.5059C379.553 28.5762 379.533 30.2722 378.487 31.3198L349.77 60.0369L318.495 91.3119L287.22 122.587L241.76 168.047C240.044 169.763 237.104 168.546 237.104 166.12L237.104 110.959L237.099 110.953L237.098 110.951Z" fill="#ABBED1" fill-opacity="0.25"/>
            </svg>

            <h1>CÓDIGO DE Vendedor</h1>

          </div>
          <div class="content">

          <div class="input_input-control">
            <label for="cpf">CPF</label>
            ${`<input type="text" id="cpf" class="input_input__IEwXa" disabled="" value=${cpf}>`}
          </div>

          <div class="input_input-control">
            <label for="cpf">Nome</label>
            ${`<input type="text" value="${name}">`}
          </div>

          <div class="qr_code">
          ${content}
          </div>
        </div>
      </div>
    </body>
  </html>
  `;
};
