import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './finished-quotations.module.css';
import createdQuotationsIcon from '../../../../../../assets/icons/created-quotations.svg';
import { useFinishedQuotation } from './hooks/useFinishedQuotations';
import { FilterComponent } from '../../../../../../shared/FilterComponent';
import { QuotationFilterOptions } from '../../interface';
import { useQuotation } from '../../../../../../contexts/quotationContext';
import { ViewAnswerQuotationModal } from '../../components/ViewAnswerQuotationModal';
import { QuotationList } from './components/QuotationListItem';
import { Quotation } from '../../../../../../@types/interface';
import useSelectedQuotation from '../../hooks/useSelectedQuotation';
import useViewModal from '../../hooks/useViewModal';

import { CreateQuotationModal } from '../CreatedQuotations/components/CreateQuotationModal';
import useCreateQuotationModal from '../../hooks/useCreateQuotationModal';
import useFeedbackModal from '../../hooks/useFeedbackModal';
import {
  createQuotationDraftController,
  deleteQuotationController,
} from '../../controller';

import { usePageVisibility } from '../../../../../../hooks/usePageVisibility';


export const FinishedQuotations = () => {
  const {
    page,
    isLoading,
    loadMoreData,
    handleRowClick,
    filterType,
    filterValue,
    handleSearch,
    handleDateSearch,
    searchedQuotations,
    setFilterType,
    resetStates,
    // searchedQuotationAnswer,
    // fetchAnswerQuotationById,
  } = useFinishedQuotation();
  const { quotationState, setQuotationState, setSelectedPage } = useQuotation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [answerIdParam, setAnswerIdParam] = useState('');
  const [previousQuotationId, setPreviousQuotationId] = useState('');

  const selectedQuotation = useSelectedQuotation();
  const viewModal = useViewModal();
  const createModal = useCreateQuotationModal();
  const feedbackModal = useFeedbackModal();

  usePageVisibility({
    onVisible: resetStates,
  });

  const handleGetQuotationByIdParam = useCallback(() => {
    const answerId = searchParams.get('id');
    if (answerId) {
      setSearchParams({});
      // await fetchAnswerQuotationById(quotationId);
      setAnswerIdParam(answerId);
      viewModal.onOpen();
    }
  }, [searchParams, setSearchParams, viewModal.onOpen]);

  const handleOpenRedoModal = async () => {
    console.log(selectedQuotation.quotation);
    try {
      setPreviousQuotationId(selectedQuotation.quotation?.id_quotation ?? '');
      feedbackModal.onOpen();
      const res = await createQuotationDraftController();
      if (res) {
        selectedQuotation.onSelect(res);
        setQuotationState(previous => {
          return {
            ...previous,
            notAnsweredQuotationList: [
              res,
              ...previous.notAnsweredQuotationList,
            ],
          };
        });
      }

      document.body.style.overflow = 'hidden';
      createModal.onOpen();
    } catch (error) {
      console.error(error);
    } finally {
      feedbackModal.onClose();
    }
  };

  const handleRemoveDraft = useCallback(
    async (draftId: string) => {
      try {
        feedbackModal.onOpen();
        await deleteQuotationController(draftId);
      } catch (error) {
        console.error(error);
      } finally {
        feedbackModal.onClose();
      }
    },
    [feedbackModal.onOpen, feedbackModal.onClose],
  );

  useEffect(() => {
    handleGetQuotationByIdParam();
  }, [handleGetQuotationByIdParam]);

  const isEmpty = useMemo(() => {
    return !isLoading && quotationState.finishedQuotationList.length === 0;
  }, [isLoading, quotationState.finishedQuotationList.length]);

  const notFound = useMemo(() => {
    return filterValue.trim().length > 0 && searchedQuotations.length === 0;
  }, [filterValue, searchedQuotations.length]);

  return (
    <>
      <ViewAnswerQuotationModal
        isOpen={viewModal.isOpen}
        onClose={() => {
          viewModal.onClose();
          selectedQuotation.onClear();
        }}
        answer={
          selectedQuotation?.quotation &&
          selectedQuotation.quotation.answers_quotation
            ? selectedQuotation.quotation.answers_quotation[0]
            : null
        }
        isOwner
        answerId={answerIdParam}
      />
      <CreateQuotationModal
        title="Refazer Cotação"
        isRedoing
        previousQuotationId={previousQuotationId}
        onRemoveQuotation={handleRemoveDraft}
        onRedoQuotation={() => setSelectedPage('created')}
        onSaveDraft={() => setSelectedPage('created')}
      />

      <div className={styles.heading}>
        <div className={styles.describe}>
          <img src={createdQuotationsIcon} alt="cotações criadas" />
          <p>Cotações Concluídas</p>
        </div>
        <div className={styles.actions}>
          <FilterComponent
            handleDateSearch={handleDateSearch}
            handleSearch={handleSearch}
            handleFilterChange={(filter: string) =>
              setFilterType(filter as QuotationFilterOptions)
            }
            defaultValue="code"
            variant={
              filterType === 'code' || filterType === 'plate'
                ? 'searchbar'
                : 'date'
            }
            upperCase={filterType === 'plate'}
            onlyNumber={filterType === 'code'}
            options={[
              {
                label: 'Número',
                value: 'code',
              },
              {
                label: 'Placa',
                value: 'plate',
              },
              {
                label: 'Data',
                value: 'date',
              },
            ]}
          />
        </div>
      </div>
      <QuotationList
        isLoading={isLoading}
        isEmpty={isEmpty}
        notFound={notFound}
        handleRowClick={(quotation: Quotation) => handleRowClick(quotation)}
        onScroll={() => loadMoreData(page)}
        onOpenRedoModal={handleOpenRedoModal}
        quotationList={
          filterValue.trim().length > 0
            ? searchedQuotations
            : quotationState.finishedQuotationList
        }
      />
    </>
  );
};
