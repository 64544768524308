import { useMemo } from 'react';
import { Quotation } from '../../../../../../@types/interface';
import styles from './autoshops-list.module.css';

interface Props {
  quotation: Quotation;
}

export const AutoshopsList = ({ quotation }: Props) => {
  const quotationAnswers = useMemo(() => {
    return quotation?.answers_quotation ?? [];
  }, [quotation?.answers_quotation]);

  return (
    <div className={styles.container}>
      <h1>Fornecedores da Cotação</h1>
      <ul className={styles['autoshops-list']}>
        {quotationAnswers.length > 0 &&
          quotationAnswers.map(answer => (
            <li key={answer.id_answer_quotation}>
              {answer?.workshop?.fantasy_name ?? ''}
            </li>
          ))}
      </ul>
    </div>
  );
};
