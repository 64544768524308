import { extendTheme } from 'native-base';

export const theme = extendTheme({
  fontConfig: {
    Inter: {
      600: {
        normal: 'Inter-Medium',
      },
    },
  },
  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat',
    div: 'Montserrat',
    mono: 'Montserrat',
  },
  components: {
    Input: {
      defaultProps: {
        borderRadius: '9px',
        h: ['3rem', '2.5rem'],
      },
      baseStyle: {
        _disabled: {
          color: '#000',
          opacity: 0.8,
          cursor: 'not-allowed',
          border: 'solid 1px #000',
          borderRadius: '10px',
        },
      },
    },
    Text: {
      defaultProps: {
        fontFamily: 'Montserrat',
        color: '#4B4B4B',
      },
    },
    FormControl: {
      defaultProps: {
        // TODO:remover essa propriedade futuramente
        // width: ['20rem', '30rem'],
        margin: 'auto',
        px: '.4rem',
      },
    },
    Link: {
      defaultProps: {
        fontFamily: 'Montserrat',
        color: '#6BB56A',
      },
    },
  },
  colors: {
    green: {
      1: '#6BB56A',
      2: '#379B36',
    },
    gray: {
      1: '#F5FAF5',
      2: '#D6D7D8',
      3: '#424242',
      4: '#4B4B4B',
      5: '#1E1E1E',
    },
    red: {
      1: '#D96C6C',
      2: '#DA0808',
    },
  },
  colorScheme: {
    green: '#6BB56A',
    red: '#F54B4F',
  },
  breakpoints: {
    base: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1280,
  },
});
