import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ChecklistResponse, getChecklistById } from './model';
import styles from './checklist-report.module.css';

export const ChecklistReport = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const checklistId = queryParams.get('checklistId');
  const [checklist, setChecklist] = useState<ChecklistResponse | null>(null);

  const fetchChecklist = async () => {
    if (checklistId) {
      const res = await getChecklistById(checklistId);
      if (res) setChecklist(res);
    }
  };

  useEffect(() => {
    fetchChecklist();
  }, []);

  return (
    <>
      <h1>imagens</h1>
      <div className={styles.container}>
        {checklist &&
          checklist.banners.map(banner => (
            <img
              src={banner.img_url}
              alt={banner.title || 'imagem sem descrição'}
            />
          ))}
      </div>
    </>
  );
};
