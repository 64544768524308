/* eslint-disable react/no-array-index-key */
import { ItemBrand, ItemBrandProps, ItemBrandType } from '../ItemBrand';
import styles from './item-quotation-list.module.css';

type ItemQuotationListType = 'itemToAnswer' | 'itemView' | 'itemWorkshopView';

interface Props {
  type: ItemQuotationListType;
  responsive: 'web' | 'mobile';
  items: ItemBrandProps[];
  itemBrandType: ItemBrandType;
  name: string;
  quantity: string;
  index: string;
  notOffer?: boolean;
}

export const ItemQuotationList = ({ responsive, items, ...rest }: Props) => {
  return (
    <div className={`${styles['item-quotation-list']} ${styles[responsive]}`}>
      <div className={styles['product-info']}>
        <div className={styles.info}>
          <b>{rest.index}.</b>
          <span>{rest.name}</span>
        </div>
        <span>{rest.quantity}</span>
      </div>
      {items.map((item, index) => (
        <ItemBrand
          responsive={responsive}
          type={rest.itemBrandType}
          key={`${index}-${item.reference}`}
          {...item}
        />
      ))}
      {rest.notOffer && (
        <ItemBrand
          responsive={responsive}
          type="empty"
          brand=""
          reference=""
          unitValue=""
          quantity=""
        />
      )}
    </div>
  );
};
