import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationProps } from '../../pages/common/Notifications/model';

const initialState = [] as NotificationProps[];

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<NotificationProps[]>) => {
      return action.payload;
    },
    addNotification: (state, action: PayloadAction<NotificationProps>) => {
      return [...state, action.payload];
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      const removeNotify = state.filter(
        notify => notify.id_notification !== action.payload,
      );
      return removeNotify;
    },
  },
});

export const { setNotification, addNotification, removeNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
