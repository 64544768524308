import { useEffect } from 'react';

interface Props {
  onVisible?: () => void;
  onHidden?: () => void;
}

export const usePageVisibility = ({ onVisible, onHidden }: Props) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (onVisible) {
          onVisible();
        }
      } else if (document.visibilityState === 'hidden') {
        if (onHidden) {
          onHidden();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [onVisible, onHidden]);
};
