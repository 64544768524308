/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import { PlusSquare } from '@phosphor-icons/react';
import imagePlaceholder from '../../assets/img/image-placeholder.svg';
import styles from './image-input.module.css';
import {
  getAssignedUrl,
  sendBucketImage,
  deleteBucketImage,
} from '../../services/api/bucket';
import { LoadingSpinner } from '../LoadingSpinner';

export interface GetAssignedUrlResponse {
  url?: string;
  filename?: string;
  image_url: string;
  key?: string;
}

type ImageInputProps = {
  width?: number;
  height?: number;
  maxImages?: number;
  onImagesSelect?: (files: File[] | null) => void;
  bucketPath: string;
  setImageUrls: (urls: string[]) => void;
  imagesUrls?: string[];
};

const ImageInput: React.FC<ImageInputProps> = ({
  width = 100,
  height = 100,
  maxImages = 5,
  onImagesSelect,
  bucketPath,
  setImageUrls,
  imagesUrls = [],
}) => {
  const [images, setImages] = useState<GetAssignedUrlResponse[]>(
    imagesUrls.map(image => ({
      url: undefined,
      filename: undefined,
      image_url: image,
      key: undefined,
    })),
  );
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [isSending, setIsSending] = useState(false);

  const handleImageClick = () => {
    if (images.length < maxImages && inputFileRef.current) {
      inputFileRef.current.click();
    } else {
      alert(`Você só pode adicionar até ${maxImages} imagens.`);
    }
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    if (files.length + images.length > maxImages) {
      alert(`Você só pode adicionar até ${maxImages} imagens.`);
      return;
    }
    setIsSending(true);

    try {
      const newImages = await Promise.all(
        files.map(async (file, index) => {
          // Obter URL assinada
          const assignedUrlData = await getAssignedUrl(file.name, bucketPath);
          // Enviar imagem para o bucket
          await sendBucketImage(file, assignedUrlData.url);
          return assignedUrlData;
        }),
      );

      // Atualiza o estado com as novas imagens
      setImages(prevImages => [...prevImages, ...newImages]);

      // Executa o callback, se houver
      if (onImagesSelect) {
        onImagesSelect(files);
      }
    } catch (error) {
      console.error('Erro ao enviar a imagem:', error);
      alert('Erro ao enviar a imagem, por favor tente novamente.');
    } finally {
      setIsSending(false);
    }
  };

  const handleRemoveImage = async (index: number) => {
    const imageToRemove = images[index];
    try {
      // Chama a função para deletar a imagem do bucket usando a key (filepath)
      if (imageToRemove.key) await deleteBucketImage(imageToRemove.key);

      // Remove a imagem da lista de imagens no estado
      setImages(prevImages => prevImages.filter((_, i) => i !== index));
    } catch (error) {
      console.error('Erro ao remover a imagem:', error);
      alert('Erro ao remover a imagem, por favor tente novamente.');
    }
  };

  useEffect(() => {
    const images_urls = images.map(image => image.image_url || '');
    setImageUrls(images_urls);
  }, [images, setImageUrls]);

  return (
    <div className={styles['image-input-container']}>
      {images.map((image, index) => (
        <div
          key={index}
          className={styles['image-wrapper']}
          style={{ width, height, position: 'relative' }}
        >
          <div>
            <img
              src={image.image_url}
              alt={`Upload ${index + 1}`}
              className={styles.image}
              style={{ width, height }}
            />
            <button
              type="button"
              className={styles['remove-button']}
              onClick={() => handleRemoveImage(index)}
              style={{
                position: 'absolute',
                top: 5,
                right: 5,
                background: 'red',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                width: 24,
                height: 24,
                cursor: 'pointer',
              }}
            >
              X
            </button>
          </div>
        </div>
      ))}

      <div className={styles['add-image-button']}>
        <button type="button" onClick={handleImageClick}>
          {isSending ? (
            <LoadingSpinner size={width / 4} />
          ) : (
            <PlusSquare size={32} />
          )}
        </button>
      </div>

      {Array.from({ length: maxImages - images.length - 1 }).map((_, index) => (
        <div
          className={styles['image-placeholder']}
          style={{ width, height }}
          onClick={handleImageClick}
          key={index}
        >
          <img
            src={imagePlaceholder}
            alt="adicionar imagem"
            style={{ width, height }}
          />
        </div>
      ))}

      <input
        ref={inputFileRef}
        type="file"
        accept="image/*"
        multiple
        style={{ display: 'none' }}
        onChange={handleImageChange}
      />
    </div>
  );
};

export default ImageInput;
