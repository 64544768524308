export const maskPhone = (value: string) => {
  if (!value) return '';

  // Detecta o código de país e remove, se necessário
  let countryCode = '';
  if (value.startsWith('+')) {
    countryCode = value.match(/^\+(\d{2})/)?.[1] || '';
    value = value.substring(3);
  }

  // Formata o número
  let formattedValue = value.replace(/[^0-9]/g, '');
  if (formattedValue.length > 10) {
    formattedValue = formattedValue.replace(
      /^(\d{2})(\d{5})(\d{4})$/,
      `($1) $2-$3`,
    );
  } else {
    formattedValue = formattedValue.replace(
      /^(\d{2})(\d{4})(\d{4})$/,
      `($1) $2-$3`,
    );
  }
  return formattedValue;
};

export const maskCep = (value: string) => {
  return value.replace(/[^0-9]/g, '').replace(/^(\d{5})(\d{3})$.*/, '$1-$2');
};

export const maskCNPJ = (value: string) => {
  return value
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
};
export const maskCpfCnpj = (text: string) => {
  if (!text) return '';
  const value = text.replace(/\D/g, '').substring(0, 14);
  const { length } = value;
  if (length > 11) {
    return value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
  }
  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};
export const currencyMask = (value: string) => {
  const valueFormatted = unformatCurrency(value);

  const masked = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  }).format(valueFormatted);
  return masked;
};

export const unformatCurrency = (value: string) => {
  const valueFormatted = value
    .replace(',', '')
    .replace('.', '')
    .replace(/\D/g, '');

  return Number(valueFormatted) / 100;
};

export const unformatCNPJ = (value: string) => {
  return value
    .replace('.', '')
    .replace('.', '')
    .replace('/', '')
    .replace('-', '');
};
export const textOnly = (value: string) => {
  return value.replace(/[^A-Za-z\s]/, '');
};
export const numberOnly = (value: string) => {
  return value.replace(/[^\d]/g, '');
};
export const maskLicensePlate = (value: string) => {
  if (!value) return '';
  return value.replace(/[^A-Za-z0-9]+/, '').toUpperCase();
};

export const numberWithCommas = (numberString: string) => {
  if (!numberString) return '';

  const [integerPart, decimalPart] = numberString.split('.');

  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return decimalPart
    ? `${integerWithCommas}.${decimalPart}`
    : integerWithCommas;
};

export const transformToPercent = (numberString: string) => {
  const percentual = parseFloat(numberString) / 100;
  const percentualFormated = `${(percentual * 100).toFixed(3)}%`;

  return percentualFormated;
};

export const unformatPercent = (Numberpercentual: string) => {
  const valueNumeric = parseFloat(Numberpercentual.replace('%', ''));
  const valueFormated = (valueNumeric / 100).toFixed(3).replace('.', '.');

  return valueFormated;
};
