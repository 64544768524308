import api from '../../../services/api/api';

export interface ChecklistResponse {
  id_checklist: string;
  description: null;
  banners: Banner[];
  repair: null;
  budget: Budget;
  created_at: Date;
  updated_at: Date;
}

export interface Banner {
  id_banner: string;
  img_url: string;
  redirect_url: null;
  type: string;
  active: boolean;
  workshop_id: string;
  title: null;
  subtitle: null;
  size: number;
  checklist_id: string;
  created_at: Date;
  updated_at: Date;
}

export interface Budget {
  id_budget: string;
  user_id: null;
  workshop_id: string;
  validity: string;
  document: string;
  name: string;
  phone: string;
  defect: string;
  comment: string;
  status: string;
  child_services: string[];
  discount: number;
  total: number;
  is_plate: boolean;
  plate: string;
  vehicle: string;
  checklist_id: string;
  created_at: Date;
  updated_at: Date;
}

export const getChecklistById = async (cheklist_id: string) => {
  try {
    const { data } = await api.get<ChecklistResponse>(
      `/checklist/${cheklist_id}`,
    );
    return data;
  } catch (error) {
    console.error(error);
  }
};
