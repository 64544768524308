import { WhatsappLogo } from '@phosphor-icons/react';
import { Button } from '../../../Button';
import { COLORS } from '../../../../styles/colors';

interface Props {
  onClick: () => void;
  text?: string;
}

export const WhatsappButton = ({ onClick, text = 'Whatsapp' }: Props) => {
  return (
    <Button handleClick={onClick}>
      <WhatsappLogo size={20} color={COLORS.WHITE} weight="bold" />
      {text}
    </Button>
  );
};
