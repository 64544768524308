import { ReactElement } from 'react';
import styles from './root.module.css';

interface Props {
  children: ReactElement;
}

export const Root = ({ children }: Props) => {
  return <div className={styles.container}>{children}</div>;
};
