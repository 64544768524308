import history from '../../../../../assets/img/LandingPage/landing-page-about-history.svg';
import styles from './page-about.module.css';
import backgroundPatternLeft from '../../../../../assets/img/LandingPage/backgroundPattern2.svg';
import backgroundPatternRight from '../../../../../assets/img/LandingPage/backgroundPattern.svg';
import { Container } from '../../Components/Container/Container';
import { textos } from './textos';
import { DescribeCard } from './components/DescribeCard';
import { ListCard } from './components/ListCard';

const About = () => {
  return (
    <Container>
      <div className={styles.container}>
        <img
          className={styles.backgroundPattern2}
          src={backgroundPatternLeft}
          alt=""
        />

        <div className={styles.section}>
          <div className={styles.title}>
            <h1>Sobre Nós</h1>
          </div>
          <DescribeCard
            img_url={textos.nossa_missao.image}
            paragraphs={textos.nossa_missao.text}
            title="Nossa Missão"
          />

          <ListCard
            img_url={textos.nossos_objetivos.image}
            items={textos.nossos_objetivos.text}
            title="Nossos Objetivos"
          />

          <DescribeCard
            img_url={history}
            paragraphs={[textos.nossa_historia.text]}
            title="Nossa História"
          />

          <ListCard
            img_url={textos.nossos_valores.image}
            items={textos.nossos_valores.text}
            title="Nossos Valores"
            describe={textos.nossos_valores.describe}
          />
        </div>
        <img
          className={styles.backgroundPattern1}
          src={backgroundPatternRight}
          alt=""
        />
      </div>
    </Container>
  );
};

export default About;
