import { Root } from './components/Root';
import { Navbar } from './components/Navbar';
import { NavbarItem } from './components/NavbarItem';
import { TopMenu } from './components/TopMenu';
import { TopMenuButton } from './components/TopMenuButton';

export const PageLayout = {
  Root,
  Navbar,
  NavbarItem,
  TopMenu,
  TopMenuButton,
};
