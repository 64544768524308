import { CSSProperties, ReactNode } from 'react';
import { CaretDown, CaretUp, IconWeight } from '@phosphor-icons/react';
import styles from './row.module.css';

interface Props {
  selected?: boolean;
  selectedWeight?: IconWeight;
  handleClick?: () => void;
  children: ReactNode;
  style?: CSSProperties;
  variant?: 'default' | 'caret';
  className?: string;
}

export const Row = ({
  selected = false,
  selectedWeight = 'light',
  handleClick,
  children,
  style,
  variant = 'default',
  className,
  ...props
}: Props) => {
  return (
    <button
      type="button"
      onClick={() => {
        if (handleClick) handleClick();
      }}
      className={`${styles.container}`}
      {...props}
    >
      <div
        className={`${styles.row} ${
          selected ? styles.selected : ''
        } ${className}`}
        style={style}
      >
        {variant === 'caret' && selected && (
          <CaretUp size={24} weight={selectedWeight} />
        )}
        {variant === 'caret' && !selected && (
          <CaretDown size={24} weight={selectedWeight} />
        )}
        {children}
      </div>
    </button>
  );
};
