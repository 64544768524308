import styles from './value-box.module.css';

interface Props {
  responsive: 'mobile' | 'web';
  value: string;
}

export const ValueBox = ({ responsive, value }: Props) => {
  return (
    <div className={`${styles['value-box']} ${styles[responsive]}`}>
      <b>Valor Unitário</b>
      <span className={styles.value}>R$ {value}</span>
    </div>
  );
};
