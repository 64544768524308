import { useEffect, useState, useRef } from 'react';
import { Socket } from 'socket.io-client';
import { socket } from '../services/api/socket';
import { getUserData } from '../utils/workshopData';
import logErrorToElastic from '../services/logs/logErrorToElastic';

export const useSocket = () => {
  const socketInstanceRef = useRef<Socket>(socket);
  const [isConnected, setIsConnected] = useState(false);
  const initializeSocket = () => {
    const socketInstance = socketInstanceRef.current;
    const { id_user } = getUserData();
    if (socketInstance) {
      socketInstance.emit('entry', { user_id: id_user });
      const handleConnect = () => {
        setIsConnected(true);
        console.log('Conectado');
      };

      const handleDisconnect = (reason: any) => {
        setIsConnected(false);
        console.log('Desconectado');
        const errorData = {
          eventType: 'disconnect',
          error: reason
            ? reason.message || 'Unknown error'
            : 'No error information',
        };
        logErrorToElastic('socket', errorData);
      };

      socketInstance.on('connect', handleConnect);
      socketInstance.on('disconnect', handleDisconnect);
      return () => {
        socketInstance.off('connect', handleConnect);
        socketInstance.off('disconnect', handleDisconnect);
        socketInstance.disconnect();
      };
    }
    // garantir que sempre retorna uma função válida
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  };
  useEffect(() => {
    initializeSocket();
    // let disconnect = initializeSocket();
    // const handleVisibilityChange = () => {
    //   if (document.visibilityState === 'visible') {
    //     if (!socketInstanceRef.current.connected) {
    //       disconnect();
    //       disconnect = initializeSocket();
    //     }
    //   }
    // };
    // document.addEventListener('visibilitychange', handleVisibilityChange);
    // return () => {
    //   document.removeEventListener('visibilitychange', handleVisibilityChange);
    //   disconnect();
    // };
  }, []);
  return {
    isConnected,
    socketInstance: socketInstanceRef.current,
  };
};
