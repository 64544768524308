import { ArrowLeft } from 'phosphor-react';
import { COLORS } from '../../styles/colors';
import styles from './back-button.module.css';

interface Props {
  handleIconClick: () => void;
}

export const BackButton = ({ handleIconClick }: Props) => {
  return (
    <button
      type="button"
      className={styles['back-btn']}
      onClick={handleIconClick}
    >
      <ArrowLeft size="3rem" color={COLORS.GREEN} weight="bold" />
    </button>
  );
};
