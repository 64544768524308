import { ComponentProps } from 'react';
import styles from './table-root.module.css';
import responsive from './table-root-responsive.module.css';

type TableRootProps = ComponentProps<'table'> & {
  AutoResponsive?: boolean;
};

export const TableRoot = ({
  children,
  AutoResponsive = false,
  ...props
}: TableRootProps) => {
  return (
    <div className={`${styles.scroll}`}>
      <table
        {...props}
        className={`${styles['table-container']} ${props.className} ${
          AutoResponsive && responsive.auto
        }`}
      >
        {children}
      </table>
    </div>
  );
};
