/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styles from './item.module.css';

interface Props {
  label: string;
  selected?: boolean;
  onClick?: () => void;
}

export const Item = ({ label, selected = false, onClick }: Props) => {
  return (
    <div
      className={`${styles.label} ${selected ? styles.selected : ''}`}
      onClick={onClick}
    >
      {label}
    </div>
  );
};
