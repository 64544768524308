import * as yup from 'yup';
import { handleError } from '../../../utils/handleError';
import { UpdateUserPasswordInput, UpdateWorkshopInput } from './interface';
import {
  getAddress,
  getWorkshopData,
  updateClientCode,
  updateUserPassword,
  updateWorkshopData,
} from './model';
import message from '../../../utils/message';

export const getWorkshopDataController = async () => {
  try {
    const res = await getWorkshopData();
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const getAddressController = async (zip: string) => {
  try {
    const res = await getAddress(zip);
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const updateWorkshopDataController = async (
  props: UpdateWorkshopInput,
) => {
  try {
    const schema = yup.object().shape({
      workshopId: yup.string(),
      corporateName: yup.string(),
      fantasyName: yup.string(),
      phone: yup.string(),
      whatsapp: yup.string(),
      email: yup.string().email('Digite um e-mail válido'),
      cnpj: yup.string(),
      type: yup.string(),
      stateRegistration: yup.number(),
      address: yup.object().shape({
        zip: yup.string().required('O CEP é obrigatório'),
        street: yup.string().required('O nome da rua é obrigatório'),
        uf: yup.string().required('A UF é obrigatória'),
        city: yup.string().required('A cidade é obrigatória'),
        district: yup.string().required('O bairro é obrigatório'),
        number: yup.string().required('O número é obrigatório'),
      }),
    });
    await schema.validate(props, {
      abortEarly: false,
    });
    const res = await updateWorkshopData(props);
    message('Dados atualizados sucesso', 'success');
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const updateUserPasswordController = async (
  props: UpdateUserPasswordInput,
) => {
  try {
    const schema = yup.object({
      newPassword: yup.string().required('A senha é um campo obrigatório'),
      confirmPassword: yup
        .string()
        .required('A confirmação de senha é um campo obrigatório')
        .oneOf([yup.ref('newPassword'), null], 'As senhas não coincidem'),
      phone: yup
        .string()
        .required('informe um número para continuar essa ação'),
    });

    await schema.validate(props, {
      abortEarly: false,
    });

    const res = await updateUserPassword({
      newPassword: props.newPassword,
      phone: props.phone,
    });
    message('Senha atualizada com sucesso', 'success');
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const updateClientCodeController = async (client_code: string) => {
  try {
    await yup
      .string()
      .required('Informe um código')
      .min(4, 'o código deve ter minimo 4 caracteres')
      .max(20, 'o código deve ter no máximo 20 caracteres')
      .validate(client_code);
    const res = await updateClientCode(client_code);
    // message('Código atualizado com sucesso', 'success');
    return res;
  } catch (error) {
    handleError(error);
  }
};
