/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Eye } from 'phosphor-react';
import { useCallback, useMemo } from 'react';
import { Modal } from '../../../../../../../../shared/Modal';
import useResumeModal from '../../../../hooks/useResumeModal';
import useSelectedQuotation from '../../../../hooks/useSelectedQuotation';
import styles from './resume-modal.module.css';
import { ViewAnswerQuotationModal } from '../../../../components/ViewAnswerQuotationModal';
import useViewModal from '../../../../hooks/useViewModal';
import useSelectedAnswer from '../../../../../autoshop/hooks/useSelectedAnswer';
import { AnswerQuotation } from '../../../../../../../../@types/interface';
import { QuotationItems } from '../../../../components/QuotationItems';

export const ResumeModal = () => {
  const viewModal = useViewModal();
  const resumeModal = useResumeModal();
  const selectedQuotation = useSelectedQuotation();
  const selectedAnswer = useSelectedAnswer();

  const approvedProposals = useMemo(() => {
    if (
      !selectedQuotation.quotation ||
      !selectedQuotation.quotation.answers_quotation
    )
      return [];
    return selectedQuotation.quotation.answers_quotation.filter(
      answer =>
        answer.confirmed &&
        answer.approved &&
        answer.finished &&
        !answer.rejected &&
        !answer.rejected_by_reciever,
    );
  }, [selectedQuotation.quotation]);
  const notApprovedProposals = useMemo(() => {
    if (
      !selectedQuotation.quotation ||
      !selectedQuotation.quotation.answers_quotation
    )
      return [];
    return selectedQuotation.quotation.answers_quotation.filter(
      answer =>
        answer.answered &&
        !answer.confirmed &&
        !answer.approved &&
        !answer.rejected &&
        !answer.rejected_by_reciever,
    );
  }, [selectedQuotation.quotation]);

  const rejectedProposals = useMemo(() => {
    if (
      !selectedQuotation.quotation ||
      !selectedQuotation.quotation.answers_quotation
    )
      return [];
    return selectedQuotation.quotation.answers_quotation.filter(
      answer => answer.rejected,
    );
  }, [selectedQuotation.quotation]);

  const rejectedByReceiverProposals = useMemo(() => {
    if (
      !selectedQuotation.quotation ||
      !selectedQuotation.quotation.answers_quotation
    )
      return [];
    return selectedQuotation.quotation.answers_quotation.filter(
      answer => answer.rejected_by_reciever,
    );
  }, [selectedQuotation.quotation]);

  const notAnsweredQuotations = useMemo(() => {
    if (
      !selectedQuotation.quotation ||
      !selectedQuotation.quotation.answers_quotation
    )
      return [];
    return selectedQuotation.quotation.answers_quotation.filter(
      answer =>
        !answer.answered && !answer.rejected && !answer.rejected_by_reciever,
    );
  }, [selectedQuotation.quotation]);

  const notConfirmedProposals = useMemo(() => {
    if (
      !selectedQuotation.quotation ||
      !selectedQuotation.quotation.answers_quotation
    )
      return [];
    return selectedQuotation.quotation.answers_quotation.filter(
      answer =>
        answer.confirmed &&
        !answer.finished &&
        !answer.rejected &&
        !answer.rejected_by_reciever,
    );
  }, [selectedQuotation.quotation]);

  const handleViewModalClose = useCallback(() => {
    viewModal.onClose();
    selectedAnswer.onClear();
  }, [selectedAnswer, viewModal]);

  const handleViewModalOpen = useCallback(
    (answer: AnswerQuotation) => {
      viewModal.onOpen();
      if (selectedQuotation.quotation) {
        selectedAnswer.onSelect({
          ...answer,
          quotation: selectedQuotation.quotation,
        });
      }
    },
    [selectedAnswer, selectedQuotation.quotation, viewModal],
  );

  return (
    <Modal
      isOpen={resumeModal.isOpen}
      onClose={() => resumeModal.onClose()}
      title="Resumo de propostas"
      size="large"
      footer={
        <button
          type="button"
          className={styles.back}
          onClick={() => resumeModal.onClose()}
        >
          Voltar
        </button>
      }
    >
      <ViewAnswerQuotationModal
        answer={selectedAnswer.answer}
        isOpen={viewModal.isOpen}
        onClose={handleViewModalClose}
        isOwner
      />
      <div className={styles.container}>
        <QuotationItems quotation={selectedQuotation.quotation} />

        <ProposalList
          proposals={notAnsweredQuotations}
          title="Não Respondida"
          onClick={handleViewModalOpen}
        />
        <ProposalList
          proposals={notApprovedProposals}
          title="Aguardando Avaliação"
          onClick={handleViewModalOpen}
        />
        <ProposalList
          proposals={notConfirmedProposals}
          title="Aguardando Confirmação"
          onClick={handleViewModalOpen}
        />
        <ProposalList
          proposals={approvedProposals}
          title="Aprovadas(finalizadas)"
          onClick={handleViewModalOpen}
        />
        <ProposalList
          proposals={rejectedProposals}
          title="Rejeitadas pelo Comprador"
          onClick={handleViewModalOpen}
          additionalClass={styles.rejected}
        />
        <ProposalList
          proposals={rejectedByReceiverProposals}
          title="Rejeitadas pelo Fornecedor"
          onClick={handleViewModalOpen}
          additionalClass={styles.rejected}
        />
      </div>
    </Modal>
  );
};

interface ProposalListProps {
  proposals: AnswerQuotation[];
  title: string;
  onClick: (answer: AnswerQuotation) => void;
  additionalClass?: string;
}

const ProposalList = ({
  proposals,
  title,
  onClick,
  additionalClass = '',
}: ProposalListProps) => {
  if (proposals.length === 0) return null;

  return (
    <div className={`${styles['autoshop-list']} ${additionalClass}`}>
      <h1>{title}</h1>
      {proposals.map((answer, index) => (
        <div
          className={styles['autoshop-item']}
          onClick={() => onClick(answer)}
          key={answer.id_answer_quotation}
        >
          <p className={styles.autoshop}>
            {index + 1}. {answer?.workshop?.fantasy_name ?? ''}
          </p>
          <Eye size={24} />
        </div>
      ))}
    </div>
  );
};
