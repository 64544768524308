import styled from 'styled-components';
import pixel from '../../styles/size';

export const Main = styled.div`
  width: 90%;
  min-height: 95vh;
  margin: 2.5vh 0;
  background-color: #fff;
  border-radius: ${pixel(12)};
  box-shadow: 0 ${pixel(6)} ${pixel(12)} #dddddd85;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  /* overflow-y: auto; */

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0rem;
    background-color: #ffffff00;
    box-shadow: 0 0 0 0 #ffffff00;
  }
  @media only screen and (min-width: 2560px) {
    width: ${pixel(1251, 1920)};
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* background-color: #f5faf5; */
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
