import { CSSProperties, ReactNode } from 'react';
import styles from './button.module.css';

type Variants =
  | 'default'
  | 'icon'
  | 'register'
  | 'border'
  | 'unstyled'
  | 'ghost'
  | 'navbar'
  | 'cancel';
type Theme = '' | 'delete' | 'pulse';

interface Props {
  variant?: Variants;
  handleClick?: () => void;
  icon?: ReactNode;
  style?: CSSProperties;
  children?: ReactNode;
  theme?: Theme;
  isSelected?: boolean;
  className?: string;
}

export const Button = ({
  handleClick,
  variant = 'default',
  icon,
  style,
  children = 'Adicionar',
  theme = '',
  isSelected = false,
  className,
}: Props) => {
  switch (variant) {
    case 'unstyled':
      return (
        <button
          type="button"
          onClick={handleClick}
          className={`${className} ${styles.unstyled} ${styles[theme]}`}
          style={style}
        >
          {children}
          {icon}
        </button>
      );
    case 'cancel':
      return (
        <button
          type="button"
          onClick={handleClick}
          className={`${className} ${styles.cancel} ${styles[theme]}`}
          style={style}
        >
          {children}
          {icon}
        </button>
      );
    case 'ghost':
      return (
        <button
          type="button"
          onClick={handleClick}
          className={`${className} ${styles.unstyled} ${styles.ghost} ${styles[theme]}`}
          style={style}
        >
          {children}
          {icon}
        </button>
      );
    case 'icon':
      return (
        <button
          type="button"
          onClick={handleClick}
          className={`${className} ${styles.button} ${styles.icon} ${styles[theme]}`}
          style={style}
        >
          {icon}
        </button>
      );
    case 'register':
      return (
        <button
          type="button"
          onClick={handleClick}
          className={`${className} ${styles.button} ${styles.register} ${styles[theme]}`}
          style={style}
        >
          {icon}
          {children}
        </button>
      );
    case 'navbar':
      return (
        <button
          type="button"
          onClick={handleClick}
          className={`${className} ${styles.button} ${styles.navbar} ${
            styles[theme]
          } ${isSelected ? styles.selected : ''} `}
          style={style}
        >
          {icon}
          {children}
        </button>
      );
    case 'border':
      return (
        <button
          type="button"
          onClick={handleClick}
          className={`${className} ${styles.button} ${styles.border} ${styles[theme]}`}
          style={style}
        >
          {icon}
          {children}
        </button>
      );
    default:
      return (
        <button
          type="button"
          onClick={handleClick}
          className={`${className} ${styles.button} ${styles.default} ${styles[theme]}`}
          style={style}
        >
          {icon}
          {children}
        </button>
      );
  }
};
