const delay = (ms: number) =>
  new Promise<void>(resolve => {
    setTimeout(resolve, ms);
  });

/**
 * Retorna uma promessa que resolve com o valor da promessa fornecida ou rejeita com um erro de timeout após o tempo especificado.
 *
 * @template T - O tipo de valor resolvido pela promessa.
 * @param {number} ms - O tempo máximo (em milissegundos) para esperar pela promessa antes de rejeitar com um timeout.
 * @param {Promise<T>} promise - A promessa a ser executada dentro do tempo limite especificado.
 * @param {string} [message='Mensagem de timeout'] - A mensagem de erro a ser usada no erro de timeout.
 * @returns {Promise<T | string>} - Uma promessa que resolve com o valor da promessa fornecida ou rejeita com uma mensagem de erro de timeout.
 */
export const timeoutPromise = <T>(
  ms: number,
  promise: Promise<T>,
  message = 'Mensagem de timeout',
): Promise<T | string> => {
  const timeout = new Promise<T | string>((_, reject) => {
    setTimeout(() => reject(new Error(message)), ms);
  });

  return Promise.race([promise, timeout]);
};

/**
 * Tenta executar uma função assíncrona repetidamente até um número especificado de vezes, com um atraso entre cada tentativa.
 *
 * @template T - O tipo de valor resolvido pela promessa.
 * @param {number} retries - O número de tentativas a serem feitas antes de falhar.
 * @param {number} ms - O tempo de atraso (em milissegundos) entre cada tentativa.
 * @param {() => Promise<T>} fn - A função assíncrona a ser executada.
 * @returns {Promise<T>} - Uma promessa que é resolvida com o valor retornado pela função assíncrona, ou rejeitada após todas as tentativas falharem.
 */

export const retry = async <T>(
  retries: number,
  ms: number,
  fn: () => Promise<T>,
): Promise<any> => {
  try {
    return await fn();
  } catch (err) {
    if (retries > 1) {
      await delay(ms);
      return retry(--retries, ms, fn);
    }
    return Promise.reject(err);
  }
};
