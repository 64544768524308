import { AlertMessage } from '../../../../../../../../shared/AlertMessage';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  container: Element | DocumentFragment;
  onCancel: () => void;
  onConfirm: () => void;
  emptyApproval: boolean;
}

export const ConfirmAnswerModal = ({
  container,
  isOpen,
  onCancel,
  onClose,
  onConfirm,
  emptyApproval,
}: Props) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      container={container}
      disableScroll
      title="Confirmar Proposta?"
      footer={
        <AlertMessage.Footer>
          <AlertMessage.CancelButton onClick={onCancel}>
            Revisar
          </AlertMessage.CancelButton>
          {!emptyApproval && <AlertMessage.ConfirmButton onClick={onConfirm} />}
        </AlertMessage.Footer>
      }
    >
      <AlertMessage.Body>
        <p
          style={{
            textAlign: 'center',
          }}
        >
          {emptyApproval ? (
            <sub
              style={{
                color: 'var(--error)',
                fontWeight: 'bold',
              }}
            >
              Você deve informar uma quantidade diferente de zero
            </sub>
          ) : (
            'Proposta enviada não poderá ser editada. Confirmar envio?'
          )}
        </p>
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};
