import { Body } from './components/Body';
import { CancelButton } from './components/CancelButton';
import { ConfirmButton } from './components/ConfirmButton';
import { Footer } from './components/Footer';
import { Root } from './components/Root';

export const AlertMessage = {
  CancelButton,
  ConfirmButton,
  Root,
  Footer,
  Body,
};
