import { Modal } from '../../../../../../../../shared/Modal';
import { Button } from '../../../../../../../../shared/Button';
import styles from './confirm-modal.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

export const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading = false,
}: Props) => {
  return (
    <Modal
      isLoading={isLoading}
      isOpen={isOpen}
      onClose={onClose}
      title="Confirmar Pedido?"
      footer={
        <div className={styles.actions}>
          <Button
            variant="cancel"
            handleClick={onClose}
            style={{
              width: '100%',
            }}
          >
            Revisar
          </Button>
          <Button
            variant="register"
            handleClick={onConfirm}
            style={{
              width: '100%',
              fontSize: '1.5rem',
              padding: '0.25rem 0.75rem',
              border: '2px solid var(--second)',
            }}
          >
            Confirmar Pedido
          </Button>
        </div>
      }
    >
      A Autocenter não se responsabiliza pelo preparo/envio do
      pedido.
    </Modal>
  );
};
