import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Lottie from 'lottie-react';
import { Checkbox } from '../../../../../../../../../../shared/Checkbox';
import styles from './companies.module.css';
import { Input } from '../../../../../../../../../../shared/Input';
import { findFavoritesWorkshopsController } from '../../../../../../controller';
import { debounce } from '../../../../../../../../../../utils/debounce';
import loadingAnimation from '../../../../../../../../../../assets/searchLottie.json';
import { WorkshopList, WorkshopOption } from '../../useSendQuotation';
import { normalizeStrings } from '../../../../../../../../../../utils/normalizeStrings';
import { CompaniesFilter } from '../../../CompaniesFilter';
import { SearchTypeOptions } from '../../../CreateQuotationModal/steps/Companies';

interface WorkshopSearchCache {
  [key: string]: WorkshopOption[];
}

interface Props {
  previousCompanies: WorkshopList[];
  workshopList: WorkshopList[];
  setWorkshopList: Dispatch<SetStateAction<WorkshopList[]>>;
}

export const Companies = ({
  setWorkshopList,
  workshopList,
  previousCompanies,
}: Props) => {
  const [companyName, setCompanyName] = useState('');
  const [companiesOptions, setCompaniesOptions] = useState<WorkshopOption[]>(
    [],
  );

  const [searchCache, setSearchCache] = useState<WorkshopSearchCache>({});
  const [isDefaultDataLoading, setIsDefaultDataLoading] = useState(false);
  const [isSearchingForWorkshop, setIsSearchingForWorkshop] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [searchType, setSearchType] = useState<SearchTypeOptions>('workshop');

  const findWorkshops = useCallback(
    async (query: string, type: string) => {
      if (query.trim().length !== 0) setIsSearchingForWorkshop(true);
      if (searchCache[`${query}-${type}`]) {
        setCompaniesOptions(searchCache[`${query}-${type}`]);
      } else {
        const res = await findFavoritesWorkshopsController(query, type);
        if (res) {
          const normalizedOptions = res.map(workshop => ({
            name: workshop.fantasy_name,
            value: workshop.id_workshop,
          }));

          const filteredOptions = normalizedOptions.filter(
            option =>
              !previousCompanies.some(
                company => company.option.value === option.value,
              ),
          );

          setSearchCache(prevCache => ({
            ...prevCache,
            [`${query}-${type}`]: filteredOptions,
          }));
          setCompaniesOptions(filteredOptions);
        }
      }
      setIsSearchingForWorkshop(false);
    },
    [previousCompanies, searchCache],
  );

  const findDefaultWorkshops = useCallback(async () => {
    setIsDefaultDataLoading(true);
    const res = await findFavoritesWorkshopsController();
    if (res) {
      const filteredFavoriteWorkshops = res.filter(
        previousResponse =>
          !previousCompanies.some(
            company => company.option.value === previousResponse.id_workshop,
          ),
      );

      setWorkshopList(
        filteredFavoriteWorkshops.map(workshop => ({
          option: { name: workshop.fantasy_name, value: workshop.id_workshop },
          selected: false,
        })),
      );
    }
    setIsDefaultDataLoading(false);
  }, [previousCompanies, setWorkshopList]);

  const debouncedFindWorkShop = debounce(
    () => findWorkshops(normalizeStrings(companyName), searchType),
    500,
  );

  useEffect(() => {
    findDefaultWorkshops();
  }, [findDefaultWorkshops]);

  useEffect(() => {
    setWorkshopList(previous =>
      previous.map(option => ({ ...option, selected: selectAll })),
    );
  }, [selectAll, setWorkshopList]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { value } = e.target;
    setCompanyName(value);
    debouncedFindWorkShop();
  };

  const handleOptionClick = (option: WorkshopOption) => {
    setWorkshopList(previous => {
      const isThisOptionAlreadyExists = previous.some(
        item => item.option.value === option.value,
      );
      if (isThisOptionAlreadyExists) {
        return sortWorkshopList(
          previous.map(item =>
            item.option.value === option.value
              ? { ...item, selected: true }
              : item,
          ),
        );
      }
      return sortWorkshopList([{ option, selected: true }, ...previous]);
    });
    scrollListToTop();
  };

  const handleCheckboxChange = (value: string) => {
    setWorkshopList(previous => {
      return sortWorkshopList(
        previous.map(option =>
          option.option.value === value
            ? { ...option, selected: !option.selected }
            : option,
        ),
      );
    });
    scrollListToTop();
  };

  const sortWorkshopList = (workshops: WorkshopList[]) => {
    return workshops.sort((a, b) => {
      if (a.selected && !b.selected) return -1;
      if (!a.selected && b.selected) return 1;
      return 0;
    });
  };

  const handleSelectAll = () => {
    setSelectAll(previous => !previous);
    scrollListToTop();
  };

  const companiesListRef = useRef<HTMLUListElement>(null);

  const scrollListToTop = () => {
    if (companiesListRef && companiesListRef.current) {
      companiesListRef.current.scrollTo({
        top: 0,
      });
    }
  };

  const handleSearchTypePlaceholder = useCallback(() => {
    if (searchType === 'product') return `Digite o nome da peça`;
    if (searchType === 'service') return `Digite o nome do serviço`;
    return `Digite o nome da empresa`;
  }, [searchType]);

  return (
    <div className={styles['companies-container']}>
      <CompaniesFilter
        handleChange={e => setSearchType(e.target.value as SearchTypeOptions)}
      />
      <Input
        name="companies"
        type="searchList"
        placeholder={handleSearchTypePlaceholder()}
        options={companiesOptions}
        value={companyName}
        handleOptionClick={handleOptionClick}
        handleChange={handleInputChange}
        isLoading={isSearchingForWorkshop}
      />
      {workshopList.length !== 0 && (
        <Checkbox
          isChecked={selectAll}
          name="selectAll"
          theme="green"
          customSize="small"
          handleChange={() => handleSelectAll()}
          label="Selecionar todas"
          style={{
            fontWeight: '600',
            color: '#333',
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        />
      )}

      {isDefaultDataLoading && <Lottie animationData={loadingAnimation} />}
      {!isDefaultDataLoading && (
        <ul className={styles['companies-list']} ref={companiesListRef}>
          {previousCompanies.map(workshop => (
            <li key={workshop.option.value}>
              <Checkbox
                isChecked={workshop.selected}
                name={workshop.option.value}
                theme="green"
                customSize="small"
                label={workshop.option.name}
                style={{
                  fontWeight: '600',
                }}
                disabled
              />
            </li>
          ))}
          {workshopList.map(workshop => (
            <li key={workshop.option.value}>
              <Checkbox
                isChecked={workshop.selected}
                name={workshop.option.value}
                theme="green"
                customSize="small"
                handleChange={() => handleCheckboxChange(workshop.option.value)}
                label={workshop.option.name}
                style={{
                  fontWeight: '600',
                }}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
