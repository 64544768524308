/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  ArrowClockwise,
  CaretDown,
  CaretUp,
  Eye,
  FileText,
  Pencil,
  Trash,
  Users,
  X,
} from 'phosphor-react';
import { useCallback, useMemo } from 'react';
import { Button } from '../../../../../../../../../shared/Button';
import { Table } from '../../../../../../../Finance/components/Table';
import styles from './desktop-list.module.css';
import { Quotation } from '../../../../../../../../../@types/interface';
import {
  dateOffset,
  formatDate,
  formatTime,
} from '../../../../../../../../../utils/dateFunctions';
import useViewModal from '../../../../../hooks/useViewModal';
import useSelectedQuotation from '../../../../../hooks/useSelectedQuotation';
import useAddCompanyModal from '../../../../../hooks/useAddCompanyModal';
import useRedoModal from '../../../../../hooks/useRedoModal';
import useFinishModal from '../../../../../hooks/useFinishModal';
import useInfiniteScroll from '../../../../../../../../../hooks/useInfinityScroll';
import { ListLoader } from '../../../../../../../../../shared/ListLoader';
import { useResponsive } from '../../../../../../../../../hooks/useResponsive';
import useCreateQuotationModal from '../../../../../hooks/useCreateQuotationModal';
import useRemoveDraftModal from '../../../../../hooks/useRemoveDraftModal';

interface Props {
  quotationList: Quotation[];
  handleRowClick: (quotation: Quotation) => void;
  onScroll: () => void;
  isLoading: boolean;
  isEmpty?: boolean;
  notFound?: boolean;
}

export const DesktopList = ({
  quotationList,
  handleRowClick,
  onScroll,
  isLoading,
  isEmpty = false,
  notFound = false,
}: Props) => {
  const viewModal = useViewModal();
  const selectedQuotation = useSelectedQuotation();
  const addCompanyModal = useAddCompanyModal();
  const redoModal = useRedoModal();
  const finishModal = useFinishModal();
  const createModal = useCreateQuotationModal();
  const removeDraftModal = useRemoveDraftModal();

  const listRef = useInfiniteScroll(onScroll);

  const sortedQuotationList = useMemo(() => {
    return quotationList.sort((a, b) => {
      if (a.draft === b.draft) return 0;
      return a.draft ? -1 : 1;
    });
  }, [quotationList]);

  const message = useMemo(() => {
    if (isEmpty) return 'Sem Novas Cotações';
    if (notFound) return 'Nenhuma Cotação Encontrada';
    return null;
  }, [isEmpty, notFound]);

  return (
    <Table.Root>
      <Table.Row className={styles['table-header']}>
        <Table.Header value="N° Cotação" align="left" />
        <Table.Header value="Data de Criação" align="left" />
        <Table.Header value="Placa" />
      </Table.Row>

      <div ref={listRef} className={styles['table-content']}>
        {message && <p>{message}</p>}
        {!message &&
          sortedQuotationList.map(quotationItem => (
            <ListItem
              key={quotationItem.id_quotation}
              quotation={quotationItem}
              isSelected={
                !!selectedQuotation.quotation &&
                selectedQuotation.quotation.id_quotation ===
                  quotationItem.id_quotation
              }
              onAddAutoshop={() => addCompanyModal.onOpen()}
              onFinish={() => finishModal.onOpen()}
              onRedo={() => redoModal.onConfirmRedoOpen()}
              onRowClick={() => handleRowClick(quotationItem)}
              onView={() => viewModal.onOpen()}
              onDraftEdit={() => {
                createModal.onOpen();
              }}
              onDraftRemove={() => {
                removeDraftModal.onOpen();
              }}
            />
          ))}
        {!message && isLoading && <ListLoader />}
      </div>
    </Table.Root>
  );
};

interface ListItemProps {
  quotation: Quotation;
  onView: () => void;
  onFinish: () => void;
  onRedo: () => void;
  onDraftEdit: () => void;
  onDraftRemove: () => void;
  onRowClick: (quotation: Quotation) => void;
  onAddAutoshop: () => void;
  isSelected: boolean;
}

const ListItem = ({
  quotation,
  isSelected,
  onAddAutoshop,
  onFinish,
  onRedo,
  onRowClick,
  onView,
  onDraftEdit,
  onDraftRemove,
}: ListItemProps) => {
  const createdAt = useMemo(() => {
    if (!quotation?.created_at) return '--/--/--';
    const date = dateOffset(new Date(quotation.created_at));

    return `${formatDate(date)} às ${formatTime(date)}`;
  }, [quotation.created_at]);

  const licensePlate = useMemo(() => {
    const plate =
      quotation?.buffer_plate?.trim() ||
      quotation?.vehicle?.license_plate?.trim() ||
      '---';
    return plate;
  }, [quotation?.buffer_plate, quotation?.vehicle?.license_plate]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onRowClick(quotation);
      e.stopPropagation();
    },
    [onRowClick, quotation],
  );

  const RowIcon = useMemo(
    () => (isSelected ? CaretUp : CaretDown),
    [isSelected],
  );

  const { sizeMobile } = useResponsive();

  //
  // const documentVisible = useVisibilityChange();

  return (
    <div className={styles.row}>
      {quotation.draft && (
        <div className={`${styles['draft-tag']} ${styles.mobile}`}>
          <FileText size={20} />
          Rascunho
        </div>
      )}
      <Table.Row
        className={`${styles['table-row']} ${
          isSelected ? styles.selected : ''
        }`}
        onClick={handleClick}
      >
        <Table.Cell alignStart className={styles['first-line']}>
          <div className={styles['quotation-code']}>
            <RowIcon size={20} />
            {quotation?.QUOTATION ?? ''}
          </div>

          {quotation.draft && (
            <div className={`${styles['draft-tag']} ${styles.desktop}`}>
              <FileText size={20} />
              Rascunho
            </div>
          )}
        </Table.Cell>
        <Table.Cell alignStart={!sizeMobile}>{createdAt}</Table.Cell>
        <Table.Cell>{licensePlate}</Table.Cell>
      </Table.Row>
      {isSelected && (
        <Table.Row className={styles['row-actions']}>
          {quotation.draft ? (
            <>
              <Button handleClick={onDraftEdit}>
                <Pencil size={20} weight="bold" />
                Editar Rascunho
              </Button>
              <Button handleClick={onDraftRemove} theme="delete">
                <Trash size={20} weight="bold" />
                Excluir Rascunho
              </Button>
            </>
          ) : (
            <>
              <Button handleClick={onView}>
                <Eye size={20} weight="bold" />
                Visualizar
              </Button>
              <Button handleClick={onAddAutoshop}>
                <Users size={20} weight="bold" />
                Adicionar Fornecedor
              </Button>
              <Button
                handleClick={onRedo}
                style={{ background: 'var(--b_grey)' }}
              >
                <ArrowClockwise
                  size={20}
                  style={{
                    transform: 'rotateY(180deg)',
                  }}
                />
                Refazer
              </Button>
              <Button handleClick={onFinish} variant="cancel">
                <X size={20} weight="bold" />
                Finalizar
              </Button>
            </>
          )}
        </Table.Row>
      )}
    </div>
  );
};
