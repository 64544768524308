/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import styles from './info-row.module.css';

interface Props {
  items: {
    name: string;
    value: string;
    fullLine?: boolean;
  }[];
}

export const InfoRow = ({ items }: Props) => {
  return (
    <div className={styles['info-row']}>
      {items &&
        items.map((item, index) => (
          <p
            className={`${item.fullLine ? styles['full-line'] : ''}`}
            key={index}
          >
            <span>{item.name}: </span> {item.value}
          </p>
        ))}
    </div>
  );
};
