/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import UserEdit from '../pages/common/UserEdit/UserEdit';
import { passwordHasChanged } from '../services/api/changePassword';
import { getObjectFromLocal, saveObjectLocally } from '../utils/localStorage';
import { useAuth } from '../hooks/useAuth';
import useLoginModal from '../hooks/useLoginModal';
import { isAuthenticated } from '../services/api/auth';

interface Props {
  children: React.ReactNode;
}

export const PrivateRoute = ({ children }: Props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const loginModal = useLoginModal();

  const auth = useAuth();

  const answerId = queryParams.get('answerId');

  useEffect(() => {
    if (isAuthenticated()) {
      auth.authenticate();
    } else {
      window.location.href = '/page-login';
    }
  }, [auth.authenticate, location.pathname, loginModal.onOpen]);

  useEffect(() => {
    if (answerId && answerId.length > 0 && !getObjectFromLocal('answerId')) {
      saveObjectLocally('answerId', answerId);
    }
  }, [answerId]);

  if (
    !children ||
    typeof children === 'string' ||
    typeof children === 'number' ||
    typeof children === 'boolean'
  )
    return <></>;
  if (passwordHasChanged()) {
    return (
      <UserEdit messageToUpdate="Por favor, para prosseguir, mude a sua senha" />
    );
  }
  return <>{children}</>;
};

export default PrivateRoute;
