import ReactDOMServer from 'react-dom/server';
import { InfoRow } from '../components/html/info-row';
import { Section } from '../components/html/section';
import { ServiceTable } from '../components/html/service-table';
import { Container } from '../components/html/container';
import { Repair } from '../../../pages/common/History/interfaces';
import { maskCpfCnpj, maskPhone } from '../../../services/helpers/mask';
import { dateOffset, formatDate, formatTime } from '../../dateFunctions';
import { getWorkshopData } from '../../workshopData';
import { QRCode } from '../../qrcode';

export const orderServicePdf = (orderService: Repair) => {
  const generateClientSection = () => {
    return {
      title: 'Cliente',
      content: InfoRow({
        content: [
          {
            name: 'Nome',
            value: orderService?.name ?? '-',
          },
          {
            name: 'Telefone',
            value: maskPhone(orderService?.phone ?? '-'),
          },
          {
            name: 'CPF/CNPJ',
            value: maskCpfCnpj(
              orderService?.user_workshop?.user?.document ?? '-',
            ),
          },
        ],
      }),
    };
  };

  const generateVehicleSection = () => {
    return {
      title: 'Veículo',
      content: InfoRow({
        content: [
          {
            name: 'Modelo',
            value: orderService?.vehicle?.name ?? '-',
          },
          {
            name: 'Placa',
            value: orderService?.vehicle?.license_plate ?? '-',
          },
          {
            name: 'km',
            value: orderService?.km?.toLocaleString('pt-br') ?? '-',
          },
        ],
      }),
    };
  };

  const generateServiceSection = () => {
    let discount = 0;
    let services: { name: string; quantity: number; unit: number }[] = [];
    if (orderService) {
      discount = orderService.discount;
      services = orderService.child_services.map(service => ({
        name: service.name,
        quantity: service.quantity,
        unit: service.total / service.quantity,
      }));
    }
    const initialDate = dateOffset(
      new Date(`${orderService.date}T${orderService.time}Z`),
    );
    let finishDate = null;
    if (orderService.finish_date && orderService.finish_time)
      finishDate = dateOffset(
        new Date(`${orderService.finish_date}T${orderService.finish_time}Z`),
      );

    return {
      title: 'Serviço',
      content: `
        ${InfoRow({
          content: [
            {
              name: 'Data de Início',
              value: `${
                orderService && orderService.created_at
                  ? `${formatDate(initialDate)} - ${formatTime(initialDate)}`
                  : '-'
              }`,
            },
            {
              name: 'Data de Término',
              value: `${
                finishDate
                  ? `${formatDate(finishDate)} - ${formatTime(finishDate)}`
                  : '-'
              }`,
            },
          ],
        })}
        ${InfoRow({
          content: [
            {
              name: 'Defeitos',
              value: orderService?.defect ?? '-',
            },
            {
              name: 'Status',
              value: orderService.finished ? 'Finalizado' : 'Em Andamento',
            },
          ],
        })}
        ${ServiceTable({
          items: services,
          discount,
        })}
      `,
    };
  };

  const generateCommentSection = () => {
    return {
      title: 'Observações',
      content: `
        ${InfoRow({
          content: [
            {
              name: 'Observações',
              value: orderService?.comment ?? '',
              fullLine: true,
            },
          ],
        })}
      `,
    };
  };

  const generateQRCode = () => {
    if (
      orderService &&
      orderService.checklist &&
      orderService.checklist.banners &&
      orderService.checklist.banners.length > 0
    ) {
      return ReactDOMServer.renderToString(
        QRCode(
          `https://oficinas.autocenterapp.com/checklist-report?checklistId=${orderService.checklist_id}`,
        ),
      );
    }
    return '';
  };

  return Container({
    workshopName: getWorkshopData()?.fantasy_name ?? 'Oficina Não encontrada',
    documentTitle: `Ordem de Serviço - ${
      orderService?.external_id || orderService.OS
    }`,
    content: `
    ${InfoRow({
      content: [
        {
          name: 'Data de Emissão',
          value:
            orderService && orderService.created_at
              ? `${formatDate(
                  new Date(orderService.created_at),
                )} - ${formatTime(new Date(orderService.created_at))}`
              : '-',
        },
      ],
    })}
      ${Section(generateClientSection())}
      ${Section(generateVehicleSection())}
      ${Section(generateServiceSection())}
      ${Section(generateCommentSection())}
      ${generateQRCode()}
    `,
  });
};
