import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BudgetProps } from './interfaces';
import { ChecklistInput } from '../../@types/interface';

interface BudgetState {
  budget: BudgetProps;
  budgets: BudgetProps[];
  checklistInput: ChecklistInput[];
}

const initalBudgetState = {
  id_budget: '',
  workshop_id: '',
  validity: '',
  defect: '',
  comment: '',
  name: '',
  phone: '',
  document: '',
  status: '',
  child_services: [],
  discount: 0,
  total: 0,
  plate: '',
  vehicle: '',
  chassi: '',
  created_at: '',
  checklist_id: '',
  checklist: null,
};

const initialState: BudgetState = {
  budget: initalBudgetState,
  budgets: [],
  checklistInput: [],
};

export const budgetSlice = createSlice({
  initialState,
  name: 'budget-slice',
  reducers: {
    setBudget: (state, action: PayloadAction<BudgetProps>) => {
      state.budget = action.payload;
    },
    setBudgets: (state, action: PayloadAction<BudgetProps[]>) => {
      state.budgets = action.payload;
    },
    removeBudget: (state, action: PayloadAction<string>) => {
      const filteredBudgets = state.budgets.filter(
        budget => budget.id_budget !== action.payload,
      );
      state.budgets = filteredBudgets;
    },
    resetBudget: state => {
      state.budget = initalBudgetState;
    },
    updateBudgetReducer: (state, action: PayloadAction<BudgetProps>) => {
      state.budgets = state.budgets.map(budget => {
        if (budget.id_budget === action.payload.id_budget) {
          return action.payload;
        }
        return budget;
      });
    },
    setBudgetChecklist: (state, action: PayloadAction<ChecklistInput[]>) => {
      state.checklistInput = action.payload;
    },
    resetChecklist: state => {
      state.checklistInput = [];
    },
  },
});

export const {
  setBudget,
  setBudgets,
  removeBudget,
  resetBudget,
  updateBudgetReducer,
  setBudgetChecklist,
  resetChecklist,
} = budgetSlice.actions;
export const budgetReducer = budgetSlice.reducer;
