import * as yup from 'yup';
import message from './message';

export const handleError = (err: any) => {
  if (!navigator.onLine) {
    message(
      'Ops, parece que você está offline. Verifique sua conexão com a internet.',
      'error',
    );
  } else if (err instanceof yup.ValidationError) {
    message(err.errors[0], 'warning');
  } else if (err.code === 'ECONNABORTED') {
    // message('tempo de requisição excedido', 'error');
    console.error(err);
  } else if (err.response && err.response.status === 502) {
    // message(
    //   'Estamos enfrentando um problema em nossos servidores, por favor tente novamente',
    //   'error',
    // );
    console.error(err);
  } else if (err.response && err.response.status === 401) {
    // message(
    //   'Estamos enfrentando um problema em nossos servidores, por favor tente novamente',
    //   'error',
    // );
    console.error(err);
  } else if (err.response.status === 500) {
    // message(
    //   'Problema de Conexão, tente novamente mais tarde ou entre em contato com o suporte!',
    //   'error',
    // );
    console.error(err);
  } else {
    message(err?.response?.data?.message ?? '', 'error');
    // console.error(err);
    console.error(err);
  }
};
