/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Lottie from 'lottie-react';
import { Checkbox } from '../../../../../../../../../../shared/Checkbox';
import styles from './companies.module.css';
import { Input } from '../../../../../../../../../../shared/Input';
import {
  findFavoritesWorkshopsController,
  findFavoritesWorkshopsV2Controller,
} from '../../../../../../controller';
import { debounce } from '../../../../../../../../../../utils/debounce';
import loadingAnimation from '../../../../../../../../../../assets/searchLottie.json';
import { WorkshopList, WorkshopOption } from '../../useSendQuotation';
import { normalizeStrings } from '../../../../../../../../../../utils/normalizeStrings';
import { CompaniesFilter } from '../../../CompaniesFilter';
import { Workshop } from '../../../../../../../../../../@types/interface';
import { SelectComponent } from '../../../../../../../../../../shared/SelectComponent';

interface WorkshopSearchCache {
  [key: string]: WorkshopOption[];
}

interface Props {
  workshopList: WorkshopList[];
  setWorkshopList: Dispatch<SetStateAction<WorkshopList[]>>;
}

export type SearchTypeOptions = 'workshop' | 'service' | 'product';

export const Companies = ({ setWorkshopList, workshopList }: Props) => {
  const [companiesOptions, setCompaniesOptions] = useState<WorkshopOption[]>(
    [],
  );

  const [searchCache, setSearchCache] = useState<WorkshopSearchCache>({});
  const [isDefaultDataLoading, setIsDefaultDataLoading] = useState(false);
  const [isSearchingForWorkshop, setIsSearchingForWorkshop] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  // const [searchType, setSearchType] = useState<SearchTypeOptions>('workshop');

  // const findWorkshops = useCallback(
  //   async (query: string, type: string) => {
  //     if (query.trim().length !== 0) setIsSearchingForWorkshop(true);
  //     if (searchCache[`${query}-${type}`]) {
  //       setCompaniesOptions(searchCache[`${query}-${type}`]);
  //     } else {
  //       const res = await findFavoritesWorkshopsController(query, type);
  //       if (res) {
  //         const normalizedOptions = res.map(workshop => ({
  //           name: workshop.fantasy_name,
  //           value: workshop.id_workshop,
  //         }));

  //         setSearchCache(prevCache => ({
  //           ...prevCache,
  //           [`${query}-${type}`]: normalizedOptions,
  //         }));
  //         setCompaniesOptions(normalizedOptions);
  //       }
  //     }
  //     setIsSearchingForWorkshop(false);
  //   },
  //   [searchCache],
  // );

  const findWorkshops = useCallback(
    async (query: string) => {
      if (query.trim().length !== 0) setIsSearchingForWorkshop(true);
      if (searchCache[`${query}`]) {
        setCompaniesOptions(searchCache[`${query}`]);
      } else {
        const res = await findFavoritesWorkshopsV2Controller(query);
        if (res) {
          const normalizedOptions = res.map(workshop => ({
            name: workshop.fantasy_name,
            value: workshop.id_workshop,
          }));

          setSearchCache(prevCache => ({
            ...prevCache,
            [`${query}`]: normalizedOptions,
          }));
          setCompaniesOptions(normalizedOptions);
        }
      }
      setIsSearchingForWorkshop(false);
    },
    [searchCache],
  );

  // merge the previously was checked and the returns from favorite workshops
  const mergeFavoriteWorkshops = useCallback(
    (newWorkshopList: Workshop[], existingWorkshops: WorkshopList[]) => {
      const newWorkshops = newWorkshopList.filter(
        newWorkshop =>
          !existingWorkshops.some(
            existingWorkshop =>
              existingWorkshop.option.value === newWorkshop.id_workshop,
          ),
      );

      setWorkshopList([
        ...existingWorkshops,
        ...newWorkshops.map(newWorkshop => ({
          option: {
            name: newWorkshop.fantasy_name,
            value: newWorkshop.id_workshop,
          },
          selected: false,
        })),
      ]);
    },
    [setWorkshopList],
  );

  const findFavoriteWorkshops = useCallback(async () => {
    setIsDefaultDataLoading(true);
    try {
      const res = await findFavoritesWorkshopsController();
      return res;
    } finally {
      setIsDefaultDataLoading(false);
    }
  }, []);

  const handleFavoriteWorkshops = useCallback(async () => {
    const newWorkshopList = await findFavoriteWorkshops();
    if (newWorkshopList) {
      mergeFavoriteWorkshops(newWorkshopList, workshopList);
    }
  }, [findFavoriteWorkshops, mergeFavoriteWorkshops]);

  // const debouncedFindWorkShop = debounce(
  //   () => findWorkshops(normalizeStrings(companyName), searchType),
  //   500,
  // );

  const debouncedFindWorkShop = debounce(
    (companyName: string) => findWorkshops(normalizeStrings(companyName)),
    500,
  );

  useEffect(() => {
    handleFavoriteWorkshops();
  }, [handleFavoriteWorkshops]);

  const handleInputChange = useCallback(
    (query: string) => {
      debouncedFindWorkShop(query);
    },
    [debouncedFindWorkShop],
  );

  const handleOptionClick = (options: WorkshopOption[]) => {
    setWorkshopList(previous => {
      const updatedList = options.reduce((acc, option) => {
        const isOptionAlreadyExists = acc.some(
          item => item.option.value === option.value,
        );

        if (isOptionAlreadyExists) {
          return acc.map(item =>
            item.option.value === option.value
              ? { ...item, selected: true }
              : item,
          );
        }
        return [{ option, selected: true }, ...acc];
      }, previous);

      return sortWorkshopList(updatedList);
    });
    scrollListToTop();
  };

  const handleCheckboxChange = (value: string) => {
    setWorkshopList(previous => {
      return sortWorkshopList(
        previous.map(option =>
          option.option.value === value
            ? { ...option, selected: !option.selected }
            : option,
        ),
      );
    });
    scrollListToTop();
  };

  const sortWorkshopList = (workshops: WorkshopList[]) => {
    return workshops.sort((a, b) => {
      if (a.selected && !b.selected) return -1;
      if (!a.selected && b.selected) return 1;
      return 0;
    });
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    setSelectAll(newValue);
    setWorkshopList(previous =>
      previous.map(option => ({ ...option, selected: newValue })),
    );
    scrollListToTop();
  };

  // const handleSearchTypePlaceholder = useCallback(() => {
  //   if (searchType === 'product') return `Digite o nome da peça`;
  //   if (searchType === 'service') return `Digite o nome do serviço`;
  //   return `Digite o nome da empresa`;
  // }, [searchType]);

  const companiesListRef = useRef<HTMLUListElement>(null);

  const scrollListToTop = () => {
    if (companiesListRef && companiesListRef.current) {
      companiesListRef.current.scrollTo({
        top: 0,
      });
    }
  };

  return (
    <div className={styles['companies-container']}>
      {/* <CompaniesFilter
        handleChange={e => setSearchType(e.target.value as SearchTypeOptions)}
      /> */}

      {/* <Input
        name="companies"
        type="searchList"
        placeholder="Digite o nome da empresa, peça ou serviço"
        // placeholder={handleSearchTypePlaceholder()}
      /> */}
      <SelectComponent
        options={companiesOptions}
        onAddOptions={handleOptionClick}
        onSearch={handleInputChange}
        isLoading={isSearchingForWorkshop}
        currentSelection={workshopList.filter(item => item.selected)}
      />

      {workshopList.length !== 0 && (
        <Checkbox
          isChecked={selectAll}
          name="selectAll"
          theme="green"
          customSize="small"
          handleChange={handleSelectAll}
          label="Selecionar todos"
          style={{
            fontWeight: '600',
            color: '#333',
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        />
      )}
      {isDefaultDataLoading && <Lottie animationData={loadingAnimation} />}
      {!isDefaultDataLoading && (
        <ul className={styles['companies-list']} ref={companiesListRef}>
          {workshopList.map(workshop => (
            <li key={workshop.option.value}>
              <Checkbox
                isChecked={workshop.selected}
                name={workshop.option.value}
                theme="green"
                customSize="small"
                handleChange={() => handleCheckboxChange(workshop.option.value)}
                label={workshop.option.name}
                style={{
                  fontWeight: '600',
                }}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
