import { useEffect, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { FloppyDisk } from 'phosphor-react';
import { Modal } from '../../../../../shared/Modal';
import { Input } from '../../../../../shared/Input';
import { Button } from '../../../../../shared/Button';
import styles from './agent-view-modal.module.css';
import logo from '../../../../../assets/icons/autocenter_logo.svg';
import { AgentPermissionsResponse, AgentResponse } from '../../interface';
import { maskCpfCnpj } from '../../../../../services/helpers/mask';
import { isWebView } from '../../../../../utils/detectUserAgent';
import { handleHtmlToPdf } from '../../../../../utils/pdf/handleHtmlToPdf';
import { agentQRcodePdf } from '../../../../../utils/pdf/AgentGasStationQRCodePrint/printQRCodeAgent';
import {
  addAgentPermissionsController,
  getAgentByIdController,
  getAgentPermissionsController,
  removeAgentPermissionsController,
} from '../../controller';
import { Checkbox } from '../../../../../shared/Checkbox';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  data: AgentResponse;
}

export const AgentViewModal = ({ isOpen, onClose, data }: Props) => {
  const [disabledEditableAgent, setDisabledEditableAgent] = useState(true);
  const [editableCPF, setEditableCPF] = useState('');
  const [editableName, setEditableName] = useState('');
  const [buttonSave, setButtonSave] = useState(false);

  const [agentPermissions, setAgentPermissions] = useState<
    AgentPermissionsResponse[]
  >([]);
  const [agent, setAgent] = useState<AgentResponse | null>(null);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  useEffect(() => {
    if (editableCPF.length > 0 && editableName.length > 0) {
      setButtonSave(true);
    }
  }, [editableCPF, editableName]);

  useEffect(() => {
    if (isOpen) {
      // fetchAgentPermissions();
      fetchAgent(data.id_agent);
      // console.log('permissoes', data.user.permissions);
      // const currentAgentPermissions = data.permissions.map(
      //   permission => permission.name,
      // );
      // setSelectedPermissions(currentAgentPermissions);
    }
    return () => {
      setSelectedPermissions([]);
    };
  }, [data.id_agent, data.permissions, isOpen]);

  const fetchAgent = async (idAgent: string) => {
    const res = await getAgentByIdController(idAgent);
    if (res) {
      if (res.user.permissions) {
        setSelectedPermissions(
          res.user.permissions.map(permission => permission.code),
        );
      }
      setAgent(res);
    }
  };

  const handleDownloadPdf = (dataInfo: AgentResponse) => {
    if (dataInfo) {
      const html = agentQRcodePdf(dataInfo);
      const pdfProps = {
        html,
        name: 'pdf',
      };
      if (isWebView === '1') {
        window.ReactNativeWebView.postMessage(JSON.stringify(pdfProps));
      } else {
        handleHtmlToPdf(pdfProps.html, pdfProps.name);
      }
    }
  };

  const fetchAgentPermissions = async () => {
    const res = await getAgentPermissionsController();
    if (res) {
      setAgentPermissions(res);
    }
  };

  const addAgentPermissions = async (user_id: string) => {
    const res = await addAgentPermissionsController({
      user_id,
      permissions: ['CREATE_QUOT', 'ANSWER_QUOT'],
      // permissions: selectedPermissions,
    });
  };

  const removeAgentPermissions = async (user_id: string) => {
    const res = await removeAgentPermissionsController({
      user_id,
      permissions: ['CREATE_QUOT', 'ANSWER_QUOT'],
      // permissions: selectedPermissions,
    });
  };

  return (
    <Modal
      title="Código de Vendedor"
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <div className={styles.actions}>
          <Button handleClick={() => handleDownloadPdf(data)}>Imprimir</Button>
          <Button
            handleClick={async () => {
              if (selectedPermissions.length > 0) {
                await addAgentPermissions(data.user_id);
              } else {
                await removeAgentPermissions(data.user_id);
              }
              onClose();
            }}
            variant="border"
          >
            <FloppyDisk size={20} />
            Salvar
          </Button>

          {/* Aguardar verificação se esse botão é necessário
          <Button
            handleClick={() => setDisabledEditableAgent(false)}
            style={{
              background: !buttonSave ? 'var(--warning)' : 'var(--secondary)',
              width: '40%',
            }}
          >
            {!buttonSave ? 'Editar' : 'Salvar'}
          </Button> */}
        </div>
      }
    >
      <div className={styles.container}>
        <Input
          label="CPF"
          name="cpf"
          disabled={disabledEditableAgent}
          onChange={e => setEditableCPF(e.target.value)}
          value={maskCpfCnpj(agent?.user?.document ?? '')}
        />
        <Input
          label="Nome"
          name="name"
          disabled={disabledEditableAgent}
          onChange={e => setEditableName(e.target.value)}
          value={agent?.user?.name ?? ''}
        />

        <QRCodeSVG
          value={data.code}
          size={200}
          imageSettings={{
            src: logo,
            height: 24,
            width: 24,
            excavate: true,
          }}
        />
      </div>

      <div className={styles.permissionsWrapper}>
        <h3>Permissões</h3>
        <ul className={styles.permissionsList}>
          <li>
            <Checkbox
              label="cotação"
              name="quotation"
              value="quotation"
              checked={
                selectedPermissions.includes('CREATE_QUOT') &&
                selectedPermissions.includes('ANSWER_QUOT')
              }
              handleChange={e => {
                const { checked, value } = e.target;
                if (checked) {
                  // setSelectedPermissions(previous => [...previous, value]);
                  setSelectedPermissions(['CREATE_QUOT', 'ANSWER_QUOT']);
                } else {
                  // setSelectedPermissions(previous =>
                  //   previous.filter(perm => perm !== value),
                  // );
                  setSelectedPermissions([]);
                }
              }}
            />
            <span>Acesso ao sistema de cotações na plataforma de vendedor</span>
          </li>
        </ul>
      </div>
    </Modal>
  );
};
